import React from 'react'
import axios from 'axios';
import moment from 'moment';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {isLoading} from '../../store/globalSlice';
import Countdown from 'react-countdown'
import {
  Grid,
  Typography,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Avatar, CardHeader, Modal, IconButton, Slide, Alert, Snackbar, Skeleton, Link, Pagination
} from '@mui/material';
import GridItem from '../../components/GridItem';
import {ReactComponent as ExternalLink} from '../../assets/external-link.svg';
import {ReactComponent as ClockAfternoon} from '../../assets/ClockAfternoon.svg';
import {ReactComponent as PauseCircle} from '../../assets/PauseCircle.svg';
import {ReactComponent as PlayCircle} from '../../assets/PlayCircle.svg';
import Lottery from '../../components/Lottery';
import TransferGems from '../../components/TransferGems';
import Statement from '../../components/Statement';
import { NFT } from '../../types/data-contracts';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import UserSummary from '../../components/UserSummary';
import Modality from '../../components/Modality';
import {CountdownRendererFn} from 'react-countdown/dist/Countdown';
import Breadcrumbs from '../../components/_ui/Breadcrumbs';
import eventBus from '../../EventBus';

const apiUrl = process.env.REACT_APP_BACKEND_URL
const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const countDownRenderer: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // window.location.reload();
    return <span style={{marginLeft: '36px'}}> — </span>
  } else {
    return <span>{hours}h {minutes}min {seconds}s</span>
  }
}

const Earn: React.FC = () => {
  const dispatch = useAppDispatch()
  const axiosPrivate = useAxiosPrivate()
  const account = useAppSelector(state => state.user.publicAddress)
  const accessToken = useAppSelector(state => state.user.accessToken)
  const data = useAppSelector(state => state.user.data);
  const loading = useAppSelector(state => state.global.loading)
  const setLoading = (loadingState: boolean) => dispatch(isLoading(loadingState))
  const [nftList, setNftList] = React.useState<NFT[] | undefined>(undefined)
  const [openModal, setOpenModal] = React.useState<{open: boolean, nft: NFT | null}>({open: false, nft: null});
  const [openError, setOpenError] = React.useState<{open: boolean, message?: string}>({open: false, message: undefined})
  const [pagesCount, setPagesCount] = React.useState<number | undefined>(undefined)
  const handleOpen = (nft: NFT) => setOpenModal({open: true, nft: nft});
  const handleClose = (nft: NFT | null) => {
    setOpenModal({open: false, nft: nft});
  }
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const handleStake = async (nft: NFT | null) => {
    if (!nft) return;
    if (disabled) return;
    setDisabled(true);

    axios.post(
      `${apiUrl}/api/v1/nfts/stake`,
      {
        token_id: nft.token_id,
        contract_address: nft.contract_address,
        is_staked: !nft.is_staked
      },
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then(() => {
      setNftList((prevState: any) => prevState.map(
          (stakedNft: { token_id: number; is_staked: boolean; }) => stakedNft?.token_id === nft.token_id ? {
          ...stakedNft,
          is_staked: !stakedNft.is_staked,
          next_reward_datetime: tomorrow
        }
        : stakedNft
        )
      )
      setOpenModal({open: false, nft})
      eventBus.dispatch('TRADE_PURCHASE');
      eventBus.dispatch('UPDATE_STATEMENT');
      setDisabled(false);
    }).catch((error) => {
      console.error(error);
      setDisabled(false);
    })
  }

  const controller = new AbortController();
  const getEarnData = async (page: number) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/nfts/`,
        {
          signal: controller.signal,
          params: { page: page, page_size: 10}
        }
      )
      setNftList(response.data.results)
      setPagesCount(Math.ceil(response.data.count / 10))
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const paginationNavigate = (page: number) => {
    setLoading(true)
    getEarnData(page).then(() => {
      setLoading(false)
      controller.abort()
    }).catch((error) => {
      console.log('!!! error:', error)
    })
  }
  const handleChange = (event:  React.ChangeEvent<unknown>, value: number) => {
    paginationNavigate(value)
  };

  React.useEffect(() => {
    paginationNavigate(1)
  }, [])
  return (
    <div className="Earn">
      <Box sx={{mb: 2}}>
        <Breadcrumbs
          items={['Earn', 'Staking']}
          description="💎 (gems) is the internal off-chain currency in Monster Ape Club.<br>
          Earn 💎 by staking your MAC NFTs or trying your luck in a lottery"
        />
      </Box>

      <Grid
        container
        spacing={2}
        direction="row-reverse"
      >
        <Grid item lg={4} xs={12} sx={{flex: 1}}>
          <GridItem variant="contained" fullHeight>
            <UserSummary nftCounter={nftList ? nftList.length : 0}/>
          </GridItem>
        </Grid>
        <Grid item lg={8} xs={12} sx={{flex: 1, flexDirection: {xs: 'column', sm: 'row', lg: 'column'}}}>
          <GridItem fullHeight>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}>
              <Typography component="span" sx={{mr: 2}} variant="h4">My NFT</Typography>
              {nftList && (
                <Button
                  href={`https://opensea.io/${account}`}
                  target="_blank"
                  rel="nofollow noopener"
                  variant="contained"
                  startIcon={< ExternalLink />}
                >
                  OpenSea
                </Button>
              )}
            </Box>
            {nftList && nftList.length > 0 || loading ? (
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{minWidth: 200}}>
                        <Typography variant="body2">NFT List</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Stake/Unstake</Typography>
                      </TableCell>
                      <TableCell sx={{minWidth: 140}}>
                        <Typography variant="body2">
                          <ClockAfternoon style={{verticalAlign: 'middle', margin: '-2px 4px 0 0'}}/>
                          Time Left
                        </Typography>
                      </TableCell>
                      <TableCell sx={{minWidth: 100}}>
                        <Typography variant="body2">Reward</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? (
                        <TableRow>
                          <TableCell>
                            <CardHeader
                              avatar={
                                <Skeleton animation="wave" width="40px" height="40px"/>
                              }
                              title={<Skeleton animation="wave" height="20px" width="100px" sx={{mb: 1}}/>}
                              subheader={<Skeleton animation="wave" height="10px" width="60px" sx={{mb: 0.5}}/>}
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" width="122px" height="40px" sx={{borderRadius: '8px'}}/>
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" height="20px" width="100px"/>
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" height="20px" width="100px"/>
                          </TableCell>
                        </TableRow>
                      ) : nftList && nftList.length && nftList.map((token: NFT) => (
                      <TableRow key={token?.token_id}>
                        <TableCell>
                          <CardHeader
                            avatar={
                              <Avatar
                                variant="rounded"
                                src={`${token?.image_thumbnail_url}`}
                              >{token?.name}</Avatar>
                            }
                            title={token?.name}
                            subheader={<small className="secondary">{token?.name}</small> }
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleOpen(token)}
                            size="medium"
                            variant="outlined"
                            color={token?.is_staked ? 'error' : 'success'}
                            startIcon={token?.is_staked ? <PlayCircle/> : <PauseCircle/>}
                          >
                            {token?.is_staked ? "Unstake" : "Stake"}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" className="secondary">
                            {token?.is_staked
                              ? <Countdown
                                  renderer={countDownRenderer}
                                  date={moment.utc(token?.next_reward_datetime).local().valueOf()}
                                />
                              : <span style={{marginLeft: '36px'}}> — </span>
                            }
                          </Typography>
                        </TableCell>
                        <TableCell dangerouslySetInnerHTML={
                          // @ts-ignore
                          {__html: token.reward
                              ?.replace('24h', 'day')
                              ?.replace(
                                'gems',
                                '<span style="font-size: 12px; position: relative; top: -2px;">💎</span>'
                              )}
                        } />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {pagesCount && pagesCount > 1 && (
                  <Box
                    component="span"
                    sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Pagination
                      variant="outlined"
                      color="secondary"
                      count={pagesCount}
                      onChange={handleChange}
                      boundaryCount={0}
                    />
                  </Box>
                )}
              </TableContainer>
            ) : (
              <Typography align="center" variant="h6" sx={{mt: 5}}>
                No Monster Ape Club NFTs in your wallet.
                <br/>
                You can purchase some &nbsp;
                <Link
                  href="https://opensea.io/collection/monsterapeclub-original"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </Link>
              </Typography>
            )}

          </GridItem>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{mt: 0}}
        direction="row"
      >
        <Grid item md={7} sm={12} xs={12}>
          <GridItem variant="contained">
            <Lottery />
          </GridItem>
          <Box sx={{mt: 3}}>
            <GridItem variant="contained">
              <TransferGems balance={data?.gems_balance} />
            </GridItem>
          </Box>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <GridItem fullHeight>
            <Statement />
          </GridItem>
        </Grid>
      </Grid>

      <Modality
        open={openModal.open}
        handleClose={() => handleClose(openModal.nft)}
        confirmText={openModal.nft?.is_staked ? 'I\'m Sure' : 'Stake it!'}
        handleConfirm={() => handleStake(openModal.nft)}
      >
        {openModal.nft?.is_staked ? (
          <>
            <Typography variant="h6" sx={{ my: 2 }}>
              <b>Are you sure you want to unstake this NFT?</b>
            </Typography>

            <Typography variant="body2" sx={{ my: 2 }}>
              You
              { openModal.nft?.reward?.split(' ')?.[0] ? ` will lose upcoming reward of ${openModal.nft?.reward?.split(' ')?.[0]} gems` : '' }
              { openModal.nft?.reward?.split(' ')?.[0] && openModal.nft?.gems_loss_probability_percent && openModal.nft?.gems_loss_probability ? ' and' : '' }
              {openModal.nft?.gems_loss_probability_percent && openModal.nft?.gems_loss_probability ?
                ` have a ${openModal.nft?.gems_loss_probability_percent}% chance of 
                losing ${openModal.nft?.gems_loss_probability} gems that you already have` : ''}!
              {/*In just {moment.utc(openModal.nft.next_reward_datetime).local().endOf('days').fromNow()} you will be rewarded with 10 Gems!*/}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ my: 2 }}>
              <b>3 easy steps to earn Gems</b>
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              1. DO NOT put your asset on sell
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              2. DO NOT transfer your asset
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} component="p">
              3. Earn 10 gems every 24 hours as a reward
            </Typography>
          </>
        )}
      </Modality>
      <Snackbar
        open={openError.open}
        TransitionComponent={props => <Slide {...props} direction="left" />}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {openError.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default React.memo(Earn)
