import React from 'react'
import {CardHeader, Fab, Skeleton, Typography} from '@mui/material';

type Props = {
  title: string,
  value?: string,
  loading?: boolean,
  icon: React.ReactNode,
  iconBackground: string,
}

const UserSummaryItem: React.FC<Props> = (props) => {
  return (
    <CardHeader
      className="SelfUserItem"
      sx={{m: 0}}
      avatar={
        props.loading
          ? (<Skeleton animation="wave" variant="circular" width={56} height={56}/>)
          : (<Fab sx={{bgcolor: props.iconBackground}} aria-label="add">{props.icon}</Fab>)
      }
      title={
        props.loading
          ? (<Skeleton animation="wave" height={9} width="68px" sx={{mb: 1}}/>)
          : (<small className="secondary">{props.title}</small>)
      }
      subheader={
        <Typography variant="h2">
          {props.loading ? <Skeleton height={34} width="40px" animation="wave"/> : props.value}
        </Typography>
      }
    />
  )
}

export default UserSummaryItem
