import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface craftState {
  statement: any[],
  isLoading: boolean,
  pagesCount: number
}

const craftState = createSlice({
  name: 'craftState',
  initialState: {
    statement: [],
    isLoading: false,
    pagesCount: 0
  } as craftState,
  reducers: {
    setStatement(state, action: PayloadAction<any>) {
      state.statement = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPagesCount(state, action: PayloadAction<number>) {
      state.pagesCount = action.payload;
    }
  }
});

export const {setStatement, setLoading, setPagesCount} = craftState.actions;
export default craftState.reducer;
