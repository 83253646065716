import React from 'react'
import {ReactComponent as Ape} from '../../assets/Ape.svg';
import {Typography} from '@mui/material';
import './NotFound.scss'

const NotFound: React.FC = () => {
  return (
    <div className="NotFound">
      <Ape/>
      <Typography component="h2" variant="h4">Page Not Found</Typography>
    </div>
  )
}

export default React.memo(NotFound)
