import React from 'react';
import {Ingredient as IIngredient} from '../../types/data-contracts';
import QuantityPicker from '../_ui/QuantityPicker/QuantityPicker';
import {useSelector} from 'react-redux';
import {addEntityToCart, getters, removeEntityFromCart} from '../../store/cartSlice';
import {useAppDispatch} from '../../hooks';

interface IRecipe extends IIngredient {}
type TradeItem = (IIngredient | IRecipe) & { module: string }

type Props = {
  item: TradeItem,
  styleType?: 'bordered' | 'default'
}

const TradeItemQuantityPicker: React.FC<Props> = ({item, styleType = 'default'}) => {
  const amount = useSelector(getters.amountById(item.id || 0))
  const dispatch = useAppDispatch()

  return (
    <QuantityPicker
      value={amount}
      styleType={styleType}
      onSub={() => dispatch(removeEntityFromCart(item))}
      onAdd={() => dispatch(addEntityToCart(item))}
    />
  )
}

export default React.memo(TradeItemQuantityPicker)
