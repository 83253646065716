import './Raffles.scss';
import React from 'react';
import {Grid, Typography} from '@mui/material';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import Raffle from '../../components/Raffle/Raffle';
import {getSkeletons} from '../../utils/getSkeletons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

type Props = {}

const Raffles: React.FC<Props> = (props) => {
  const [raffles, setRaffles] = React.useState<any[]>([]);
  const axiosPrivate = useAxiosPrivate();

  const getData = async (url: string, add: boolean = true) => {
    // const items = [...(add ? ingredientsList : []), ...getSkeletons(6, Date.now())];
    // setIngredientsList(items);
    try {
      const response = await axiosPrivate.get(url);
      setRaffles([
        // ...items.filter(({skeleton}: {skeleton: boolean}) => !skeleton),
        ...response.data.results
      ]);

      console.log('response.data.results', response.data.results);
      // setNext(response.data.next);
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    getData(`${apiUrl}/api/v1/lottery/raffle/`, false);
  }, []);

  return (
    <div className="Raffles">
      <Typography variant="h1" component="h1">
        Raffles
      </Typography>
      <br/>

      <Grid spacing={2} container>
        <DndProvider backend={HTML5Backend}>
          {raffles.map((item: any) => <Raffle key={item.id} item={item}/>)}
        </DndProvider>
      </Grid>
    </div>
  )
}

export default React.memo(Raffles)
