import React from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppBar, Grid, Typography, IconButton, Menu, MenuItem, Button} from '@mui/material';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';
import truncateAddress from '../../utils/truncateAddress'
import {accessToken, oneTimeNonce, publicAddress, refreshToken} from '../../store/authSlice';
import {useMatch, useNavigate} from 'react-router-dom';
import web3Modal from '../../utils/initWeb3Modal';
import {ReactComponent as ArrowDown} from '../../assets/ArrowDown.svg';
import {ReactComponent as LogOut} from '../../assets/LogOut.svg';
import {ReactComponent as Gem} from '../../assets/Gem.svg';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {setUser, setDataLoading} from '../../store/authSlice';
import eventBus from '../../EventBus';
import './Header.scss'
import useLogin from '../../hooks/useLogin';

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const account = useAppSelector(state => state.user.publicAddress)
  const setAccount = (account: string | undefined) => dispatch(publicAddress(account as string))
  const setNonce = (nonce: string | undefined) => dispatch(oneTimeNonce(nonce as string))
  const setAccess = (access: string | undefined) => dispatch(accessToken(access))
  const setRefresh = (refresh: string | undefined) => dispatch(refreshToken(refresh))
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const balance = useAppSelector(state => state.user.data?.gems_balance)
  const axiosPrivate = useAxiosPrivate();
  const matchDashboardPath = useMatch({ path: '/' });
  const { account, disconnect, connectWallet, chainId, nonce, error } = useLogin();

  const getData = async () => {
    try {
      dispatch(setDataLoading(true))
      const response = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/self/`)
      dispatch(setUser(response.data))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    getData();
  }, ['account']);

  React.useEffect(() => {
    eventBus.on('TRADE_PURCHASE', () => {
      getData();
    });
  }, []);

  // const disconnect = async () => {
  //   await web3Modal.clearCachedProvider();
  // };
  const handleLogout = () => {
    localStorage.removeItem('Access')
    localStorage.removeItem('Refresh')
    localStorage.removeItem('flasks')
    setAccount(undefined)
    setNonce(undefined)
    setAccess(undefined)
    setRefresh(undefined)
    handleClose()
    disconnect().then(() => navigate('/login'))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // React.useEffect(() => {
  //   window.ethereum.on('accountsChanged', async (wallets: Array<string>) => {
  //     console.log('__accountsChanged__', wallets);
  //     if (!wallets.length) handleLogout();
  //     else setAccount(wallets[0]);
  //   });
  // }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (matchDashboardPath) {
    return (
      <div></div>
    );
  }

  return (
    <AppBar
      color="transparent"
      className="Header"
      position="static"
      sx={{pb: 0}}
    >
      {account ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/*<Box sx={{ '& > :not(style)': { m: 1 }, mr: 2 }}>*/}
          {/*  <Tooltip title="Logout">*/}
          {/*    <Fab color="inherit" size="small" aria-label="add" onClick={handleLogout}>*/}
          {/*      &larr;*/}
          {/*    </Fab>*/}
          {/*  </Tooltip>*/}
          {/*  <Fab color="secondary" size="small" aria-label="edit">*/}
          {/*    <Badge badgeContent="+6" color="primary">*/}
          {/*        <Notification/>*/}
          {/*    </Badge>*/}
          {/*  </Fab>*/}
          {/*</Box>*/}
          <div className="account-path" aria-describedby={id}>
            <div>
              <Typography align="right" variant="subtitle2" component="div">{truncateAddress(account)}</Typography>
              {/*<Typography align="right" variant="caption" component="div" className="secondary">user</Typography>*/}
              <Typography align="right" variant="caption" component="div" className="secondary">
                <span style={{ position: 'relative', top: 2 }}>💎 { balance }</span>
                {/*<Gem style={{ marginLeft: 6, width: 16, height: 16, verticalAlign: 'middle' }} />*/}
              </Typography>
            </div>
            <Jazzicon
              diameter={38}
              seed={jsNumberForAddress(`${account}`)}
              paperStyles={{marginLeft: '16px', border: '2px solid #29EBCC'}}
            />
            <IconButton
              onClick={handleClick}
              aria-label="Close"
              component="span"
              sx={{ml: 1}}
              size="small"
            >
              <ArrowDown />
            </IconButton>
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              marginTop: '8px',
              '& .MuiList-root': {
                padding: 0,
              }
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <div className="logout-btn" onClick={handleLogout}>
              <Typography variant="body2"><LogOut/> Logout</Typography>
            </div>
          </Menu>

        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >&nbsp;</Grid>
      )}
    </AppBar>
  )
}

export default React.memo(Header)
