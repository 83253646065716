
import * as React from 'react'

const _setItem = Storage.prototype.setItem;
Storage.prototype.setItem = function(key, value) {
  if (this === window.localStorage) {
    const event = new Event('storageChange')
    document.dispatchEvent(event)
  }
  // @ts-ignore
  _setItem.apply(this, arguments);
}

const _removeItem = Storage.prototype.removeItem;
Storage.prototype.removeItem = function(key) {
  if (this === window.localStorage) {
    const event = new Event('storageChange')
    document.dispatchEvent(event)
  }
  // @ts-ignore
  _removeItem.apply(this, arguments);
}

function useLocalStorage(key: string) {
  const storageItem = localStorage.getItem(key)

  const [storedValue, setValue] = React.useState(
    !!storageItem ? JSON.parse(storageItem) : null
  )

  const setLocalItem = () => {
    /** local storage update is not that fast */
    /** it makes sure that we are getting the new value  */
    setTimeout(() => {
      const itemValueFromStorage = localStorage.getItem(key)
      const value = itemValueFromStorage && JSON.parse(itemValueFromStorage)
      setValue(value)
    }, 50)
  }

  const setStoredValue = (value: string | object) => {
    const parsedValue = typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, parsedValue)
  }

  React.useEffect(() => {
    document.addEventListener(
      'storageChange',
      setLocalItem,
      false
    )

    return () => document.removeEventListener('storageChange', setLocalItem)
  }, [])

  return { storedValue, setStoredValue }
}

export default useLocalStorage
