import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from './index';

interface tradeItemsState {
  items: {
    ingredients: any[],
    recipes: any[],
    serums: any[]
  },
  isLoading: {
    ingredients: boolean,
    recipes: boolean,
    serums: boolean
  }
}

const tradeItemsData = createSlice({
  name: 'tradeItems',

  initialState: {
    items: {
      ingredients: [],
      recipes: [],
      serums: []
    },
    isLoading: {
      ingredients: true,
      recipes: true,
      serums: true
    }
  } as tradeItemsState,

  reducers: {
    setItems(state, action: PayloadAction<{ key: 'ingredients' | 'recipes' | 'serums', value: any[] }>) {
      state.items[action.payload.key] = action.payload.value;
    },
    setLoading(state, action: PayloadAction<{ key: 'ingredients' | 'recipes' | 'serums', value: boolean }>) {
      state.isLoading[action.payload.key] = action.payload.value;
    }
  }
});

export const getters = {
  itemsByType: (type: 'ingredients' | 'recipes' | 'serums') =>
    (state: RootState) => state.tradeItems.items[type],
  loadingByType: (type: 'ingredients' | 'recipes' | 'serums') =>
    (state: RootState) => state.tradeItems.isLoading[type],
}

export const {
  setItems,
  setLoading
} = tradeItemsData.actions;

export default tradeItemsData.reducer;
