import React, {useEffect} from 'react';
import {Snackbar, Alert, Slide} from '@mui/material';
import './Login.scss';
import useLogin from '../../hooks/useLogin';
import {ReactComponent as Logo} from '../../assets/LogoNew.svg';
import {ReactComponent as Instagram} from '../../assets/Instagram.svg';
import {ReactComponent as Discord} from '../../assets/Discord.svg';
import {ReactComponent as X} from '../../assets/X.svg';
import {useAppSelector} from '../../hooks';
// import {accessToken, oneTimeNonce, publicAddress, refreshToken} from '../../store/authSlice';

interface Props {
  isGamePage?: boolean,
  isDemo?: boolean
}

const Login: React.FC<Props> = ({ isGamePage = false, isDemo = false }) => {
  const { account, disconnect, connectWallet, chainId, nonce, error, open } = useLogin();
  const accessToken = useAppSelector(state => state.user.accessToken)

  const play = () => {
    const id = isDemo ? '2158200' : '1275370';
    console.log('play', `steam://run/${id}?token=${accessToken}`);
    window.location.href = `steam://run/${id}?token=${accessToken}`;
  }

  useEffect(() => {
    if (account) {
      if (isGamePage) play();
    }
  }, [isGamePage, account ])

  return (
    <div className="Login">
      <div className="frame">
        <div className="logo"><Logo /></div>
        <div className="button-container">
        {account ?
          (isGamePage ? <button className="button" onClick={() => play()}>PLAY GAME</button>
          : <button className="button" onClick={() => disconnect()}>DISCONNECT</button>)
          : (
          <button className="button" onClick={() => connectWallet(isGamePage ? 'steam' : '/')}>ENTER THE LAB</button>
        )}
        </div>

        <Snackbar
          open={error.open}
          TransitionComponent={props => <Slide {...props} direction="left" />}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          open={open}
          TransitionComponent={props => <Slide {...props} direction="left" />}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Please, confirm nonce in your Metamask
          </Alert>
        </Snackbar>
      </div>
      <div className="footer">
        <div className="soc-nets">
          { false && <a href="#">
            <Instagram />
          </a> }
          <a href="https://discord.com/invite/HS2RcnD8ws" target="_blank">
            <Discord />
          </a>
          <a href="https://x.com/monsterapeclub?s=21&t=ZutAj6tFDLG67DLVdSSe-w" target="_blank">
            <X />
          </a>
        </div>
        <div className="copyright">
          © 2021, NHance Studio
        </div>
      </div>
    </div>
  )
}

export default React.memo(Login)
