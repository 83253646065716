import React from 'react';
import {ReactComponent as CartIcon} from '../../assets/Cart.svg';
import './CartCounter.scss';
import {useAppSelector} from '../../hooks';

type Props = {
  onClick: () => void,
}

const CartCounter: React.FC<Props> = (props) => {
  const cartItems = useAppSelector(state => state.cart.items)

  return (
    <div onClick={props.onClick} className="CartCounter">
      <CartIcon />
      <div className="value">{cartItems.length}</div>
    </div>
  )
}

export default React.memo(CartCounter)