import React from 'react';
import cx from 'classnames';
import './Breadcrumbs.scss'
import {ReactComponent as BreadcrumbsDelimiter} from '../../../assets/BreadcrumbsDelimiter.svg';
import {ReactComponent as ArrowLeft} from '../../../assets/ArrowLeft.svg';
import {Typography, useMediaQuery, useTheme} from '@mui/material';

type Props = {
  items: string[] | {
    title: string,
    onClick?: () => void,
    back?: boolean
  }[],
  description?: string,
  children?: React.ReactNode
}

const Breadcrumbs: React.FC<Props> = (props) => {
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography className={cx('Breadcrumbs', matchXsWidth && '-mobile-view')} variant="h1" component="h1">
        <div>
          {props.items.map((item, index) => {
            const isObject = typeof item === 'object';
            return (
              <span key={index}>
                {!!index && <BreadcrumbsDelimiter className="breadcrumbs-delimiter" />}
                <span className="item" onClick={() => { isObject && item.onClick && item.onClick() }}>
                  {isObject && item.title && <ArrowLeft className="back" />}
                  {isObject ? item.title : item}
                </span>
              </span>
            )
          })}
        </div>
        {props.children}
      </Typography>

      {props.description &&
        <Typography variant="subtitle2" style={{marginBottom: 0}} className="secondary" dangerouslySetInnerHTML={
          // @ts-ignore
          { __html: props.description }
        } />}
    </>
  )
}

export default React.memo(Breadcrumbs)