import React from 'react'
import {Box} from '@mui/material';
import {ReactComponent as Ape} from '../../assets/Ape.svg';
import {ReactComponent as Gem} from '../../assets/Gem.svg';
import {ReactComponent as Flask} from '../../assets/Flask.svg';
import UserSummaryItem from '../UserSummaryItem';
import {useAppSelector} from '../../hooks';

type Props = {
  nftCounter: number
}

const UserSummary: React.FC<Props> = (props) => {
  const globalLoading = useAppSelector(state => state.global.loading)
  const data = useAppSelector(state => state.user.data)
  const loading = useAppSelector(state => state.user.dataLoading)

  return (
    <div className="SelfUser">
      <Box sx={{
        justifyContent: 'space-between',
        gap: '12px',
        height: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: {xs: 'column', sm: 'row', mg: 'column', lg: 'column' },
        p: 0
      }}
      >
        <UserSummaryItem
          title="Total NFT's"
          value={props.nftCounter.toString()}
          loading={globalLoading}
          icon={<Ape/>}
          iconBackground={'#29EBCC'}
        />
        <UserSummaryItem
          title="Gems Balance"
          value={data?.gems_balance}
          loading={loading}
          icon={
            <span style={{ fontSize: '24px' }}>💎</span>
          }
          iconBackground={'transparent'}
        />
        <UserSummaryItem
          title="Total Ingredients"
          value={(data?.total_ingredients_amount || 0).toString()}
          loading={loading}
          icon={<Flask/>}
          iconBackground={'#35EDF9'}
        />
      </Box>
    </div>
  )
}

export default React.memo(UserSummary)
