import './Craft.scss'
import React from 'react';
import {Grid, Skeleton, CircularProgress, Typography} from '@mui/material';
import GridItem from '../../components/GridItem/GridItem';
import MyTradeItems from '../../components/MyTradeItems';
import Recipe from '../../components/Recipe';
import CraftingStatement from '../../components/CraftingStatement';
import Laboratory from '../../components/Laboratory';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import {useAppSelector} from '../../hooks';
import eventBus from '../../EventBus';
import useLocalStorage from '../../hooks/useLocalStorage';

type Props = {}

const Craft: React.FC<Props> = (props) => {
  const ingredients = useAppSelector(state => state.user?.data?.ingredients);
  const loading = useAppSelector(state => state.user?.dataLoading);
  const { storedValue: flasks } = useLocalStorage('flasks');
  // const loading = true;

  const onDoubleClick = (item: any) => {
    const itemsInCrafting = flasks.reduce((acc: any, flask: any) => {
      const itemId = flask.counter?.[0]?.ingredientData?.id;
      if (itemId) {
        if (!acc[itemId]) acc[itemId] = 0;
        acc[itemId] += flask.counter.length;
      }
      return acc;
    }, {});
    const id = item.ingredient?.id;
    if (itemsInCrafting[id] + 1 > item.amount) return false;
    eventBus.dispatch('LABORATORY_ITEM_SELECTED', item);
  }

  return (
    <div className="Craft">
      <Typography variant="h1" component="h1">
        Craft
      </Typography>
      <Typography variant="subtitle2" className="secondary" sx={{mb: 2}}>
        Craft ingredients to create serum
      </Typography>

      <Grid spacing={2} container>
        <DndProvider backend={HTML5Backend}>
          <Grid item sm={3} xs={12}>
            <GridItem variant="contained" marginBottom>
              <MyTradeItems
                name="ingredients"
                module="ingredients"
                amountVisible={false}
                hasHeader={false}
                useDraggable={true}
                onDoubleClick={onDoubleClick}
                checkCraftingAmount={true}
              />
            </GridItem>
            <GridItem variant="contained" marginBottom>
              <MyTradeItems
                name="serums"
                itemKey="serum"
                module="serums"
                amountVisible={true}
                hasHeader={false}
                useDraggable={false}
              />
            </GridItem>
            <Recipe />
          </Grid>
          <Grid item sm={9} xs={12} sx={{minWidth: '100px'}}>
            <GridItem variant="transparent" marginBottom>
              {/*addItemFunction={onDoubleClick}*/}
              {!loading && <Laboratory ingredients={ingredients} />}
              {loading && <div className="loading"><CircularProgress /></div>}
            </GridItem>
            <GridItem variant="contained">
              <CraftingStatement />
            </GridItem>
          </Grid>
        </DndProvider>
      </Grid>
    </div>
  )
}

export default React.memo(Craft)
