import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SelfUser as IUser} from '../types/data-contracts';

interface userDataState {
  publicAddress?: string,
  oneTimeNonce?: string,
  accessToken?: string,
  refreshToken?: string,
  availableBalance?: string,
  data?: IUser,
  dataLoading: boolean
}

const userData = createSlice({
  name: 'userData',

  initialState: {
    publicAddress: undefined,
    oneTimeNonce:  undefined,
    accessToken: undefined,
    refreshToken: undefined,
    availableBalance: undefined,
    data: undefined,
    dataLoading: true
  } as userDataState,

  reducers: {
    publicAddress(state, action: PayloadAction<string>){
      state.publicAddress = action.payload
    },
    oneTimeNonce(state, action: PayloadAction<string>) {
      state.oneTimeNonce = action.payload
    },
    accessToken(state, action: PayloadAction<any>) {
      state.accessToken = action.payload
    },
    refreshToken(state, action: PayloadAction<any>) {
      state.refreshToken = action.payload
    },
    availableBalance(state, action: PayloadAction<any>) {
      state.availableBalance = action.payload
    },
    setUser(state, action: PayloadAction<any>) {
      state.data = action.payload
      state.dataLoading = false;
    },
    setDataLoading(state, action: PayloadAction<boolean>) {
      state.dataLoading = action.payload;
    },
    setUserBalance(state, action: PayloadAction<string>) {
      if (state.data) state.data = {...state.data, gems_balance: action.payload};
    }
  }
})

export const {
  publicAddress,
  oneTimeNonce,
  accessToken,
  refreshToken,
  availableBalance,
  setUser,
  setDataLoading,
  setUserBalance
} = userData.actions
export default userData.reducer
