import React from 'react';
import cx from 'classnames';
import './TradeItem.scss'
import {ReactComponent as Question} from '../../assets/Question.svg';
import TradeItemQuantityPicker from './TradeItemQuantityPicker';
import {Ingredient as IIngredient} from '../../types/data-contracts';
// import {Recipe as IRecipe} from '../../types/data-contracts';
import {Button, Skeleton, useMediaQuery, useTheme} from '@mui/material';
import Modality from '../Modality/Modality';
import {isLoading} from '../../store/globalSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {useSelector} from 'react-redux';
import {addEntityToCart, getters} from '../../store/cartSlice';

const apiUrl = process.env.REACT_APP_BACKEND_URL

interface IRecipe extends IIngredient {}
type TradeItem = (IIngredient | IRecipe) & { module: string, count: number }

const TradeItem: React.FC<TradeItem> = (props) => {
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch()
  const axiosPrivate = useAxiosPrivate()
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [info, setInfo] = React.useState<IIngredient | IRecipe | null>(null);
  const loading = useAppSelector(state => state.global.loading)
  const setLoading = (loadingState: boolean) => dispatch(isLoading(loadingState))
  const amount = useSelector(getters.amountById(props.id || 0))

  const getIngredientData = async () => {
    setLoading(true)
    try {
      const response = await axiosPrivate.get(`${apiUrl}/api/v1/lab/${props.module}/${props.id}`)
      setInfo(response?.data)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleQuestionClick = () => {
    getIngredientData()
    setOpenModal(true)
  }

  return (
    <div className={cx('Ingredient')}>
      <div className="image" style={{backgroundImage: `url(${props.image_thumb})`}}>
        {/*<div className="price">*/}
        {/*  /!*<div className="price__label">Price</div>*!/*/}
        {/*  <div className="price__value">💎 {parseFloat(props.price || '0')}</div>*/}
        {/*</div>*/}
      </div>
      <div className="content">
        <div className="name">{props.title}</div>
        <div className="counts">
          <div className="quantity">{props.count || '0'} 📦</div>
          <div className="price">
            <div className="label">Price</div>
            <div className="value">{parseFloat(props.price || '0')} 💎</div>
          </div>
        </div>
        <div className="row">
          {/*<Question className="cursor-pointer" onClick={handleQuestionClick}/>*/}
          <div className="level">Level: {props.level}</div>
          {amount
            ? (<>
              <div className="badge">In cart</div>
              <TradeItemQuantityPicker item={props} styleType="bordered" />
            </>) : (
              <Button
                size="small"
                variant="contained"
                component="div"
                onClick={() => dispatch(addEntityToCart(props))}
              >
                Add to cart
              </Button>
            )
          }
        </div>
      </div>
      <Modality
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={props.title}
      >
        <>
          {loading || !info ? (
            <>
            <Skeleton sx={{mb: 1}} animation="wave" height="1rem"/>
            <Skeleton sx={{mb: 1}} animation="wave" height="1rem" />
            <Skeleton sx={{mb: 1}} animation="wave" height="1rem" />
            </>
            ) : (
              <div className={cx('detail-info', matchXsWidth && '-mobile-view')}>
                <div className="description">{info.description}</div>
                <div className="image-container">
                  { info.image_full && <img className="image" width={203} src={info.image_full} alt={props.title} /> }
                  {!amount && <Button
                    size="small"
                    variant="contained"
                    component="div"
                    style={{marginTop: 22}}
                    onClick={() => {
                      dispatch(addEntityToCart(props))
                      setOpenModal(false)
                    }}
                  >
                    Add to cart
                  </Button>}
                </div>
              </div>
            )
          }
        </>
      </Modality>
    </div>
  )
}

export default React.memo(TradeItem)
