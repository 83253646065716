import React from 'react';
import './Raffle.scss';
import cx from 'classnames';
import GridItem from '../GridItem';
import {Typography, Grid, Button, Alert, Snackbar} from '@mui/material';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';
import truncateAddress from '../../utils/truncateAddress';
import moment from 'moment';
import Countdown from 'react-countdown';
import {CountdownRendererFn} from 'react-countdown/dist/Countdown';
import {clearCart} from '../../store/cartSlice';
import eventBus from '../../EventBus';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {AxiosError} from 'axios';

const countDownRenderer: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return '00:00:00';
  } else {
    return <span>{hours}:{minutes}:{seconds}</span>
  }
}

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Raffle: React.FC<{ item: any }> = ({ item }) => {
  const winner = Boolean(item.winners?.length);
  const axiosPrivate = useAxiosPrivate();
  const [snackbar, setSnackbar] = React.useState<{open: boolean, message?: string, type?: 'success' | 'error'}>(
    {open: false, message: undefined, type: 'success'}
  );

  const buy = async () => {
    try {
      const response = await axiosPrivate.post(`${apiUrl}/api/v1/lottery/raffle/${item.id}/buy_ticket/`);
      setSnackbar({open: true, message: 'Your purchase was successful!', type: 'success'});
      window.location.reload();
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        setSnackbar({open: true, message: e?.response?.data?.[0] || 'An error occurred', type: 'error'});
      }
    }
  }

  return (
    <>
      <Grid item md={4} sm={12} xs={12} sx={{flex: 1}}>
        <GridItem variant="contained-tiny" fullHeight className={cx('Raffle', winner && '-winner')}>
          <div className="top-row">
            <div className="label">Raffle #{item.id}</div>
            {!winner && !item.is_finish && <div className="live">Live</div>}
          </div>
          <div className="image">
            <img src={item.prizes[0].image.image} alt={item.title} />
          </div>
          <div className="title">
            { item.title }
          </div>
          <div className="params">
            {winner && item.winners.length === 1 && <div className="param">
              <div className="param-label">Winner:</div>
              <div className="param-value">
                <Jazzicon
                  diameter={20}
                  paperStyles={{marginRight: '8px', minWidth: '20px' }}
                  seed={jsNumberForAddress(item.winners?.[0]?.wallet?.public_address)}
                />
                {truncateAddress(item.winners?.[0]?.wallet?.public_address)}
              </div>
            </div>}
            {winner && item.winners.length > 1 &&
              <>
                <div className="param">
                  <div className="param-label">Winners:</div>
                  <div className="param-value"></div>
                </div>
                  {item.winners.map((winner: any, i: number) =>
                    <div key={i} className="param">
                      <div className="param-label">&nbsp;&nbsp;&nbsp;{winner.position} place</div>
                      <div className="param-value">
                        <Jazzicon
                          diameter={20}
                          paperStyles={{marginRight: '8px', minWidth: '20px' }}
                          seed={jsNumberForAddress(winner?.wallet?.public_address)}
                        />
                        {truncateAddress(winner?.wallet?.public_address)}
                      </div>
                    </div>
                  )}
              </>
            }
            <div className="param">
              <div className="param-label">Tickets:</div>
              <div className="param-value">{item.tickets - item.number_tickets_sold}/{item.tickets}</div>
            </div>
            <div className="param">
              <div className="param-label">Price:</div>
              <div className="param-value">💎 {item.ticket_price}</div>
            </div>
            {!winner &&
            <div className="param">
              <div className="param-label">Countdown:</div>
              <div className="param-value">
                <Countdown
                  renderer={countDownRenderer}
                  date={moment.utc(item?.finish_at).local().valueOf()}
                />
              </div>
            </div>}
            {item.is_finish &&
              <div className="param">
                <div className="param-label">Finished:</div>
                <div className="param-value">{moment.utc(item?.finish_at).local().format('DD-MM-YY HH:mm')}</div>
              </div>}
          </div>
          {((!item.ticket_bought || item.multiple_purchase) && !item.is_finish) &&
          <div className="button-container">
            <Button
              variant="contained"
              color="warning"
              sx={{ margin: '16px auto 0'}}
              onClick={buy}
            >
              <Typography variant="body2" noWrap>Buy ticket</Typography>
            </Button>
          </div>
          }
        </GridItem>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default React.memo(Raffle)
