import React from 'react'
import {
  Grid,
  Box,
  Skeleton, useMediaQuery, useTheme,
} from '@mui/material';
import GridItem from '../../components/GridItem';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import TradeItem from '../../components/TradeItem';
import Cart from '../../components/Cart';
import CartCounter from '../../components/Cart/CartCounter';
import MyTradeItems from '../../components/MyTradeItems';
import cx from 'classnames';
import './Trade.scss';
import Breadcrumbs from '../../components/_ui/Breadcrumbs';
import {Player} from '../TopPlayers/TopPlayers';
import {User} from '../LastMonster/LastMonster';
import {NFT} from '../../types/data-contracts';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import {getSkeletons} from '../../utils/getSkeletons';
import {useAppSelector} from '../../hooks';
import {useSelector} from 'react-redux';
import {getters} from '../../store/tradeItemsSlice';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

type Props = {
  title: string,
  description: string,
  text: string,
  module: 'ingredients' | 'recipes'
}

const Trade: React.FC<Props> = (props) => {
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const axiosPrivate = useAxiosPrivate();
  const [ingredientsList, setIngredientsList] = React.useState<any[]>(
    getSkeletons(6, Date.now())
  );
  const [next, setNext] = React.useState<string | null>(null);
  const [mobileCart, setMobileCart] = React.useState<boolean>(false)
  const [ref, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const myIngredients = useSelector(getters.itemsByType('ingredients'));
  const isItemsLoading = useSelector(getters.loadingByType('ingredients'));

  const myI = myIngredients.reduce((acc, item) => {
    acc[item.ingredient.id] = item.amount;
    return acc;
  }, {});

  const getTradeData = async (url: string, add: boolean = true) => {
    const items = [...(add ? ingredientsList : []), ...getSkeletons(6, Date.now())];
    setIngredientsList(items);
    try {
      const response = await axiosPrivate.get(url);
      setIngredientsList([
        ...items.filter(({skeleton}: {skeleton: boolean}) => !skeleton),
        ...response.data.results
      ])
      setNext(response.data.next);
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (isVisible && next) {
      getTradeData(next);
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (!isItemsLoading) {
      getTradeData(`${apiUrl}/api/v1/lab/${props.module}/`, false);
    }
  }, [isItemsLoading]);

  return (
    <div className={cx('Trade')}>
      <Box sx={{mb: mobileCart ? 2 : 5}}>
        {mobileCart ? (
          <Breadcrumbs items={[{
            title: 'Cart',
            back: true,
            onClick: () => setMobileCart(false)
          }]}>
          </Breadcrumbs>
        ) : (
          <>
            {/*, props.title*/}
            <Breadcrumbs items={['Shop']} description={props.description}>
              {matchXsWidth && <div className="cart-counter">
                <CartCounter onClick={() => { setMobileCart(true) }}/>
              </div>}
            </Breadcrumbs>
          </>
        )}
      </Box>

      {mobileCart ? (
        <Cart name={props.text} module={props.module} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item lg={8} xs={12} className="ingredients-grid-item">
              {ingredientsList && ingredientsList.length > 0 && (
                <Box
                  style={{maxHeight: 950, overflow: 'auto'}}
                  sx={{paddingRight: {xs: 0, sm: '20px'}}}
                  className={cx('ingredients-container', matchXsWidth && '-mobile-view')}
                >
                  <Grid
                    container
                    spacing={2}
                    className="track"
                  >
                    {ingredientsList.map((item: any) =>
                      <Grid item key={item.id} sm={4}>
                        {item.skeleton
                          ? <Skeleton sx={{borderRadius: '20px'}} height={293} animation="wave" />
                          : <TradeItem {...item} count={myI[item.id]} module={props.module} />
                        }
                      </Grid>
                    )}
                    <div ref={ref} style={{ height: '1px', width: '100%' }}></div>
                  </Grid>
                </Box>
              )}
            </Grid>
            {!matchXsWidth &&
              <Grid item lg={4} xs={12}>
                <GridItem>
                  <Cart name={props.text} module={props.module} />
                </GridItem>
                <Box sx={{mt: 3}}>
                  <GridItem>
                    <MyTradeItems
                      hasHeader
                      amountVisible
                      module={props.module}
                      name={props.text}
                    />
                  </GridItem>
                </Box>
              </Grid>
            }
          </Grid>
        </>
      )}

      {matchXsWidth &&
        <Box sx={{mt: 5}}>
          <Box sx={{mb: 2}}>
            <Breadcrumbs items={['My ingredients']} description="To know what you need to buy, and what you have already" />
          </Box>
          <MyTradeItems
            hasHeader
            amountVisible
            module={props.module}
            name={props.text}
          />
        </Box>
      }
    </div>
  )
}

export default React.memo(Trade)
