import React from 'react'
import {Button, Grid, TextField, Typography, Box, Alert, Snackbar} from '@mui/material';
import GridItem from '../../components/GridItem/GridItem';
import {ReactComponent as GemSmall} from '../../assets/GemSmall.svg';
import {useAppDispatch, useAppSelector} from '../../hooks';
import cx from 'classnames';
import axios from 'axios';
import { useReward } from 'react-rewards';
import './SlotMachine.scss'
import Breadcrumbs from '../../components/_ui/Breadcrumbs';
import eventBus from '../../EventBus';
import {setUserBalance} from '../../store/authSlice';
import {usePrompt} from '../../hooks/usePrompt';

const sliceIntoChunks = (arr: [], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

const initialState = [[0, 1, 2], [3, 4, 1] , [2, 1, 3]]
const fakeSlotsCount = [31, 34, 40];
const rewardConfig = {
  startVelocity: 40,
  elementCount: 200,
  elementSize: 12,
}

const SlotMachine: React.FC = () => {
  const accessToken = useAppSelector(state => state.user.accessToken)
  const balance = useAppSelector(state => state.user.data?.gems_balance);
  const [limit, setLimit] = React.useState<number | undefined>(undefined)
  const [bet, setBet] = React.useState<string>('10');
  const [betFieldDisabled, setBetFieldDisabled] = React.useState<boolean>(false)
  const [betError, setBetError] = React.useState<{show: boolean, message?: string}>({ show: false })
  const [spinResult, setSpinResult] = React.useState<Array<Array<number>>>(initialState)
  const [won, setWon] = React.useState<boolean>(false)
  const [wonToday, setWonToday] = React.useState<string | undefined>(undefined)
  const [totalWon, setTotalWon] = React.useState<string | undefined>(undefined)
  const [winnerLines, setWinnerLines] = React.useState<number[]>([])
  const [wonGems, setWonGems] = React.useState<string>('')
  const [animated, setAnimated] = React.useState<boolean>(false)
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [spinning, setSpinning] = React.useState<boolean>(false)
  const randomArrayInRange = (min: number, max: number, n: number) => Array.from({length: n}, () => Math.floor(Math.random() * (max - min + 1)) + min)
  const { reward: confettiReward } = useReward('confettiReward', 'confetti',rewardConfig)
  const [spinLengths, setSpinLengths] = React.useState(['', '', '']);
  const exceptThisSymbols = ['e', 'E', '+', '-', '.', ','];
  const dispatch = useAppDispatch();

  const handleImageLoad = (event: React.SyntheticEvent) => {
    const imageHeight = (event.target as HTMLImageElement).clientHeight;
    const slotItemMargin = 24;
    const initialItemsCount = 3;
    const calcLength = (count: number) => `${(imageHeight + slotItemMargin + 2) * -1 * (initialItemsCount + count)}px`;
    setSpinLengths([
      calcLength(fakeSlotsCount[0]),
      calcLength(fakeSlotsCount[1]),
      calcLength(fakeSlotsCount[2])
    ]);
  }

  const winnerLinesTemplate = ['0,3,6', '1,4,7', '2,5,8'];
  const formatWinnerLines = (lines: Array<Array<number>>) => {
    return lines.filter(line => line.length === 3).map(line => winnerLinesTemplate.indexOf(line.join(',')));
  }

  const addDecimals = (string: string) => {
    const arr = string.split('.');
    if (arr.length === 1) return `${string}.00`;
    if (arr[1]?.length === 1) return `${string}0`;
    return string;
  }

  const handleSpin = () => {
    setDisabled(true);
    setSpinning(true);
    setBetFieldDisabled(true);
    if (!limit) {
      const balanceAfterSpin = (parseFloat(balance || '') - parseFloat(bet || '10')).toString();
      dispatch(setUserBalance(addDecimals(balanceAfterSpin)));
    }
    if (limit && limit > 1) setLimit(limit - 1);
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/lottery/slot_machine/`,
      {bet_size: parseFloat(bet) || 10},
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then((response) => {
      setAnimated(true)
      setWon(false)
      const responseChunks = sliceIntoChunks(response.data.result, 3)
      const result = [
        [...spinResult[0], ...randomArrayInRange(0, 4, fakeSlotsCount[0]), ...responseChunks[0]],
        [...spinResult[1], ...randomArrayInRange(0, 4, fakeSlotsCount[1]), ...responseChunks[1]],
        [...spinResult[2], ...randomArrayInRange(0, 4, fakeSlotsCount[2]), ...responseChunks[2]]
      ]
      setSpinResult(result)
      setTimeout(async () => {
        setWon(response.data.is_won)
        setWonToday(response.data.total_won_today)
        setTotalWon(response.data.all_won)
        const lines = formatWinnerLines(response.data.winning_lines);
        setWinnerLines(lines)
        setWonGems(response.data.won_gems)
        setSpinResult(responseChunks)
        setAnimated(false)
        setBetFieldDisabled(false)
        eventBus.dispatch('TRADE_PURCHASE');
        await getData();
        setDisabled(false)
        setSpinning(false);
        if (response.data.is_won) confettiReward()
      }, 4000)
    }).catch((error) => {
      console.error(error);
      setAnimated(false)
      setDisabled(false)
      setSpinning(false)
      setBetFieldDisabled(false)
      setBet('0');
      eventBus.dispatch('TRADE_PURCHASE');
      getData();
    })
  }

  const [snackbar, setSnackbar] = React.useState<{ open: boolean, message?: string, type?: 'success' | 'error' }>(
    {open: false, message: undefined, type: 'success'}
  );
  usePrompt(() => {
    setSnackbar({open: true, message: 'Can\'t leave than lottery is spinning!', type: 'error'});
  }, 'Leave screen?', spinning);

  const reset = () => {
    setWon(false);
    setWinnerLines([]);
    setWonGems('');
  }

  const handleBetChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setBetError({show: false});
    const value = Number(event.target.value);
    const balanceValue = balance ? parseFloat(balance) : 0;
    if (value > balanceValue) setBetError({show: true, message: 'The Bet exceeds the balance of your Gems'});
    else if (value < 1) setBetError({show: true, message: 'Invalid value'});
    setBet(event.target.value);
  }, [balance])

  const getData = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/lottery/slot_machine/info`, {
      headers: { 'Authorization': `Bearer ${accessToken}`}
    }).then((response) => {
      setWonToday(response.data.total_won_today)
      setLimit(response.data.limit)
      setTotalWon(response.data.all_won)
    }).catch((error) => {
      console.error(error)
    })
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setDisabled(true);
      await getData();
      setDisabled(false);
    }
    fetchData();
  }, [])

  React.useEffect(() => {
    if (limit && limit > 0) setBet('5');
  }, [limit])

  return (
    <div className="SlotMachine" style={{
      ['--spin1']: spinLengths[0],
      ['--spin2']: spinLengths[1],
      ['--spin3']: spinLengths[2]
    } as React.CSSProperties}>
      <Box sx={{mb: 2}}>
        <Breadcrumbs items={['Earn', 'Lottery']} description="Try your luck and win 💎 (gems)" />
      </Box>
      <Grid container spacing={2}>
        <Grid lg={8} xs={12} item>
          <Grid container spacing={2} justifyContent="space-between" mb={2}>
            <Grid item className="bet-area">
              <>
                {won ? (
                  <Typography mt={1} mr={2} component="h2" variant="h4">You won&nbsp; 💎 {wonGems}!</Typography>
                ) : (
                  <>
                    {limit === 0 && (
                      <>
                        <Typography mt={1} mr={2} component="h2" variant="h4">Bet</Typography>
                        <TextField
                          variant="outlined"
                          error={betError.show}
                          disabled={betFieldDisabled}
                          value={bet}
                          type="number"
                          onChange={handleBetChange}
                          onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                          helperText={betError.show && betError.message}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: '💎',
                        }}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            </Grid>
            <Grid item>
              <div className="spinner-action">
                <Button
                  disabled={betError.show || disabled}
                  variant="contained"
                  id="spin"
                  onClick={won ? reset : handleSpin}
                >
                  {won ? 'Go on!' : 'SPIN'}
                </Button>
              </div>
            </Grid>
          </Grid>
          <GridItem variant="contained-tiny">
            <>
              <div className="slot-machine">
                <div className={cx('indicators', '-first', winnerLines && winnerLines.includes(0) && '-win-line' )}/>
                <div className={cx('indicators', '-second', winnerLines && winnerLines.includes(1) && '-win-line' )}/>
                <div className={cx('indicators', '-third', winnerLines && winnerLines.includes(2) && '-win-line' )}/>
                <div className={cx('reels', animated && '-animated')}>
                  <div className="reel" >
                    <div className={cx('slots', won && winnerLines.map(i => `-winner-${i}`))}>
                      {spinResult[0].map((name, index) => (
                        <div className="slot" key={index} >
                          <img src={`/symbols/${name}.avif`}/>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="reel">
                    <div className={cx('slots', won && winnerLines.map(i => `-winner-${i}`))}>
                      {spinResult[1].map((name, index) => (
                        <div className="slot" key={index}>
                          <img src={`/symbols/${name}.avif`}/>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="reel">
                    <div className={cx('slots', won && winnerLines.map(i => `-winner-${i}`))}>
                      {spinResult[2].map((name, index) => (
                        <div className="slot" key={index}>
                          <img onLoad={handleImageLoad} src={`/symbols/${name}.avif`}/>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </GridItem>
          <div className="confetti">
            <span id="confettiReward" style={{width: '10%', marginLeft: '10%', height: '100%', background: "red"}} />
          </div>
        </Grid>
        <Grid lg={4} xs={12} item>
          {limit !== 0 && (
            <>
              <GridItem variant="contained-tiny" className="side-info">
                <Typography mb={1} className="secondary" component="div" variant="body2">Free Attempts</Typography>
                <Typography variant="h3" component="span"><b>{limit || 0}</b> </Typography>
                <Typography variant="body2" component="span" className="secondary" >left</Typography>
              </GridItem>
            </>
          )}
          <GridItem variant="contained-tiny" className="side-info">
            <Typography mb={1} className="secondary" component="div" variant="body2">Won today</Typography>
            <Typography variant="h3" component="span"><b>💎 {wonToday ? wonToday : 0}</b> </Typography>
            <Typography mt={2} mb={1} className="secondary" component="div" variant="body2">Total win</Typography>
            <Typography variant="body1" component="span">💎 {totalWon}</Typography>
          </GridItem>
          <GridItem variant="contained-tiny" className="side-info">
            <Typography mb={1} className="secondary" component="div" variant="body2">Easy rules</Typography>
            <ul className="squared-list">
              <li>
                <Typography className="secondary" variant="body2">Extra spin costs 1 💎</Typography>
              </li>
              <li>
                <Typography className="secondary" variant="body2">You might lose everything you got</Typography>
              </li>
            </ul>
          </GridItem>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setSnackbar({...snackbar, open: false})}
      >
        <Alert severity={snackbar.type} sx={{width: '100%'}}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default React.memo(SlotMachine)
