import './LeaderBoard.scss';
import React from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import Raffle from '../../components/Raffle/Raffle';
import {getSkeletons} from '../../utils/getSkeletons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CraftingStatementItem from '../../components/CraftingStatementItem/CraftingStatementItem';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';
import truncateAddress from '../../utils/truncateAddress';
import moment from 'moment/moment';
import GridItem from '../../components/GridItem/GridItem';
import eventBus from '../../EventBus';
import {useAppSelector} from '../../hooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const PER_PAGE = 10;

type Props = {}

const LeaderBoard: React.FC<Props> = (props) => {
  const [gameProfile, setGameProfile] = React.useState<any>({
    attempts_to_save_leader_board: 0,
    nfts_count: 0
  });
  const [items, setItems] = React.useState<any[]>([]);
  const [accessCodes, setAccessCodes] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const axiosPrivate = useAxiosPrivate();
  const [snackbar, setSnackbar] = React.useState<{ open: boolean, message?: string, type?: 'success' | 'error' }>(
    {open: false, message: undefined, type: 'success'}
  );

  const userToken = useAppSelector(state => state.user?.accessToken);

  const buyAttempts = async () => {
    try {
      const response = await axiosPrivate.post('/api/v1/game/leader_board/but_attempts/', {
        amount: 1
      });
      getGameProfile(`${apiUrl}/api/v1/game/profile/`, false);
      setSnackbar({open: true, message: 'Successful purchase', type: 'success'});
      eventBus.dispatch('TRADE_PURCHASE');
      console.log('q3', response);
    } catch (e) {
      console.error(e);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    }
  }

  const buyKey = async () => {
    try {
      const response = await axiosPrivate.post('/api/v1/game/steam/access_code/purchase/');
      getAccessCodes(`${apiUrl}/api/v1/game/steam/access_code/`, false);
      eventBus.dispatch('TRADE_PURCHASE');
      console.log('q2', response);
    } catch (e) {
      console.error(e);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    }
  }

  const getGameProfile = async (url: string, add: boolean = true) => {
    try {
      const response = await axiosPrivate.get(url);
      setGameProfile(response.data);
    } catch (error) {
      console.error(error)
    }
  }

  const getData = async (url: string, add: boolean = true) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(url);
      setItems([
        ...response.data.results
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  const getAccessCodes = async (url: string, add: boolean = true) => {
    try {
      const response = await axiosPrivate.get(url);
      setAccessCodes([
        ...response.data.results
      ]);
    } catch (error) {
      console.error(error)
    }
  }

  const playNow = () => {
    try {
      // window.location.href = `steam://run/1275370?token=${userToken}`;
      window.location.href = `steam://run/2158200?token=${userToken}`;
    } catch (e) {
      setSnackbar({open: true, message: 'Error on open Steam', type: 'error'});
    }
  }

  // const difficulties = [
  //   {title: 'NORMAL', code: 'normal'},
  //   {title: 'HARD', code: 'hard'},
  //   {title: 'NIGHTMARE', code: 'nightmare'},
  // ];

  const sortingItems = [
    // { title: 'TOP KILLS', code: 'killed_enemies' },
    { title: 'TOP SCORE', code: 'score' },
    { title: 'TOP TIME', code: 'time' }
  ];

  const copyAccessCode = () => {
    navigator.clipboard.writeText(accessCodes?.[0]?.code);
    setSnackbar({open: true, message: 'Copied', type: 'success'});
  }

  const [sort, setSort] = React.useState(sortingItems[0]);

  React.useEffect(() => {
    getData(`${apiUrl}/api/v1/game/leader_board/?ordering=-${sort.code}`, false);
  }, [sort]);

  React.useEffect(() => {
    getAccessCodes(`${apiUrl}/api/v1/game/steam/access_code/`, false);
    getGameProfile(`${apiUrl}/api/v1/game/profile/`, false);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const val = sortingItems.find(({code}) => code === newValue);
    if (val) setSort(val);
  };

  return (
    <div className="LeaderBoard">
      <Typography variant="h1" component="h1">
        Top Players
      </Typography>
      <br/>

      <DndProvider backend={HTML5Backend}>
        <Grid spacing={2} container>
          <Grid item sm={9} xs={12} sx={{flex: 1}}>
            <GridItem variant="contained" marginBottom>
              <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '16px'}}>
                <Tabs value={sort.code} onChange={handleTabChange}>
                  {sortingItems.map((item) =>
                    <Tab key={item.code} label={item.title} value={item.code}/>
                  )}
                </Tabs>
              </Box>
              {!isLoading && !items.length ? <div style={{color: '#7A86A1', paddingTop: '8px'}}>
                  No items found
                </div>
                : <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={80}>
                        <span className="secondary table-header-cell">#</span>
                      </TableCell>
                      <TableCell>
                        <div className="secondary table-header-cell">User Address</div>
                      </TableCell>
                      <TableCell width={240}>
                        <div className="secondary table-header-cell">Date</div>
                      </TableCell>
                      {sort.code === 'killed_enemies' && <TableCell width={140}>
                        <div className="secondary table-header-cell">Killed enemies</div>
                      </TableCell>}
                      {sort.code === 'time' && <TableCell width={140}>
                        <div className="secondary table-header-cell">Time</div>
                      </TableCell>}
                      {sort.code === 'score' && <TableCell width={140}>
                        <div className="secondary table-header-cell">Score</div>
                      </TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && [...Array(PER_PAGE)].map((e, index) => (
                      <TableRow key={index}>
                        {[...Array(4)].map((e, index) => (
                          <TableCell key={`t_${index}`}>
                            <div className="skeleton-container">
                              <Skeleton sx={{borderRadius: '10px', width: '100%'}} key={index} animation="wave" height="24px"/>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {!isLoading && items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div style={{marginRight: '30px'}}>{(index + 1)}</div>
                        </TableCell>
                        <TableCell>
                          <div className="user">
                            <Jazzicon
                              diameter={44}
                              paperStyles={{marginRight: '16px', minWidth: '44px'}}
                              seed={jsNumberForAddress(`${item.player.public_address}`)}
                            />
                            <Typography variant={'body2'} component="div">
                              {truncateAddress(item.player.public_address)}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography variant={'body2'}>
                            {moment.utc(item.create_at).local().format('DD-MM-YY HH:mm')}
                          </Typography>
                        </TableCell>
                        {sort.code === 'killed_enemies' && <TableCell>
                          <Typography variant={'body2'}>
                            {item.killed_enemies}
                          </Typography>
                        </TableCell>}
                        {sort.code === 'time' && <TableCell>
                          <Typography variant={'body2'}>
                            {item.time}
                          </Typography>
                        </TableCell>}
                        {sort.code === 'score' && <TableCell>
                          <Typography variant={'body2'}>
                            {item.score}
                          </Typography>
                        </TableCell>}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </GridItem>
          </Grid>
          <Grid item md={3} sm={12} xs={12} sx={{flex: 1}}>
            <GridItem variant="contained-tiny" className="action-card">
              <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align="center" sx={{my: 1}} variant="h3">Let's begin</Typography>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{margin: '16px auto 0'}}
                  onClick={playNow}
                >
                  <Typography variant="body2" noWrap>Play Now</Typography>
                </Button>
              </Box>
            </GridItem>
            {!!accessCodes?.length && <GridItem variant="contained-tiny" className="action-card">
              <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align="center" sx={{my: 1}} variant="h3">Your access code:</Typography>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center', marginTop: '16px'}}>
                  <Typography align="center" variant="body2" noWrap component="div" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {accessCodes?.[0]?.code}
                    <ContentCopyIcon sx={{ fontSize: 16 }} style={{ cursor: 'pointer' }} onClick={copyAccessCode} />
                  </Typography>
                </div>
              </Box>
            </GridItem>}
            {!accessCodes?.length && <GridItem variant="contained-tiny" className="action-card">
              <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align="center" sx={{my: 1}} variant="h3">You don't have access code</Typography>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center', marginTop: '16px'}}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={buyKey}
                  >
                    <Typography variant="body2" noWrap>Buy Key</Typography>
                  </Button>
                  <Typography align="center" variant="body2" noWrap component="div">500 💎</Typography>
                </div>
              </Box>
            </GridItem>}
            <GridItem variant="contained-tiny">
              <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <Typography align="center" variant="body2" noWrap component="div">Attempts count:</Typography>
                <Typography align="center" sx={{my: 1}} variant="h2">{gameProfile.attempts_to_save_leader_board || 0}</Typography>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center', marginTop: '16px'}}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={buyAttempts}
                  >
                    <Typography variant="body2" noWrap>Buy More Attempts</Typography>
                  </Button>
                  <Typography align="center" variant="body2" noWrap component="div">20 💎</Typography>
                </div>
              </Box>
            </GridItem>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbar.open}
          // autoHideDuration={2000}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          onClose={() => setSnackbar({...snackbar, open: false})}
        >
          <Alert severity={snackbar.type} sx={{width: '100%'}} style={{alignItems: 'center'}}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </DndProvider>
    </div>
  )
}

export default React.memo(LeaderBoard)
