import React from 'react'
import {Box, Grid, List, ListItem, ListSubheader, Pagination, Skeleton, Typography} from '@mui/material';
import {ReactComponent as PauseCircle} from '../../assets/PauseCircle.svg';
import {ReactComponent as Database} from '../../assets/Database.svg';
import {ReactComponent as PlayCircle} from '../../assets/PlayCircle.svg';
import {ReactComponent as Trophy} from '../../assets/Trophy.svg';
import {ReactComponent as PaperPlaneTiltDown} from '../../assets/PaperPlaneTiltDown.svg';
import './Statement.scss'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {NFTSmall, Transaction} from '../../types/data-contracts';
import moment from 'moment';
import eventBus from '../../EventBus';

const StatementIcon: React.FC<{
  kind: Transaction['kind'],
  gems?: Transaction['gems'],
  amount?: Transaction['amount'],
  nftId?: NFTSmall['token_id']
}> = ({kind, gems, nftId, amount}) => {

 switch (kind) {
   case 'add': return (
     <Typography  variant="body2">
       <span className="icon" style={{color: '#BF9911'}}><Database /></span>
       Nft added
     </Typography>
   )
   case 'earn': return (
     <Typography  variant="body2">
       <span className="icon -emoji">💰</span>
       Earned {gems} 💎
     </Typography>
   )
   case 'stake': return (
     <Typography variant="body2">
       <span className="icon" style={{color: '#29EBCC'}}><PauseCircle/></span>
       NFT #{nftId} staked
     </Typography>
   )
   case 'unstake': return (
     <Typography variant="body2">
       <span className="icon" style={{color: '#FF8E75'}}><PlayCircle /></span>
       NFT #{nftId} unstaked
     </Typography>
   )
   case 'staking_halted': return (
     <Typography variant="body2"><span className="icon" style={{color: '#FF8E75'}}><PlayCircle /></span>
       Staking halted
     </Typography>
   )
   case 'sold': return (
     <Typography variant="body2"><span className="icon" style={{color: '#BF9911'}}><Database /></span>
       Staking halted
     </Typography>
   )
   case 'gems_won': return (
     <Typography variant="body2"><span className="icon" style={{color: '#FFFB92'}}><Trophy /></span>
       Gems won
     </Typography>
   )
   case 'gems_received': return (
       <Typography variant="body2">
         <span className="icon" style={{color: '#7EEB29'}}><PaperPlaneTiltDown /></span>
         Gems received
       </Typography>
   )
   case 'burned_gems': return (
     <Typography variant="body2">
       <span className="icon -emoji" >🔥</span>
       Burned {amount} Gems
     </Typography>
   )
   case 'gems_sent': return (
     <Typography variant="body2">
       <span className="icon -emoji" >💸</span>
       {gems} Gems sent
     </Typography>
   )
   case 'slot_machine_won': return (
     <Typography variant="body2">
       <span className="icon -emoji" >🏆</span>
       Lottery win
     </Typography>
   )
   case 'raffle_buy_ticket': return (
     <Typography variant="body2">
       <span className="icon -emoji" >💵</span>
       Raffle buy ticket
     </Typography>
   )
   case 'raffle_won': return (
     <Typography variant="body2">
       <span className="icon -emoji" >🏆</span>
       Raffle won
     </Typography>
   )
   case 'slot_machine_lose': return (
     <Typography variant="body2">
       <span className="icon -emoji" >❌</span>
       Lottery loss
     </Typography>
   )
   case 'ingredient_purchase': return (
     <Typography variant="body2">
       <span className="icon -emoji" >🛒</span>
       Ingredient purchase
     </Typography>
   )
   default: return <Typography variant="body2">{kind}</Typography>
  }
}

const Statement: React.FC = () => {
  const axiosPrivate = useAxiosPrivate()
  const [data, setData] = React.useState<Transaction[] | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [pagesCount, setPagesCount] = React.useState<number | undefined>(undefined)

  const getDashboardData = async (page: number) => {
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/nfts/statement/`,
        {
          params: { page: page, page_size: 10 }
        }
      )
      setData(response.data.results)
      setPagesCount(Math.ceil(response.data.count / 10))
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const paginationNavigate = (page: number) => {
    setLoading(true)
    getDashboardData(page).then(() => {
      setLoading(false)
    })
  }

  React.useEffect(() => {
    const init = () => {
      paginationNavigate(1);
    }
    init();

    eventBus.on('UPDATE_STATEMENT', init);
    return () => { eventBus.remove('UPDATE_STATEMENT', init); }
  }, [])

  const handleChange = (event:  React.ChangeEvent<unknown>, value: number) => {
    paginationNavigate(value)
  };

  return (
    <div className="Statement">
      <Typography component="h2" variant="h4">Statement</Typography>
      {!data?.length && !loading ? (
        <Box sx={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
          <Typography variant="h6">You have no transactions yet</Typography>
        </Box>
      ) : (
        <>
          <List
            sx={{
              maxHeight: {lg: '396px', xs: '300px'},
              position: 'relative',
              overflowY: 'auto',
              overflowX: 'auto',
              paddingRight: '10px'
            }}
            subheader={<div />}
          >
            <ListSubheader>
              <Grid container>
                <Grid xs={3} item>
                  <Typography variant={'body2'} className="secondary">
                    Date
                  </Typography>
                </Grid>
                <Grid xs={8} item>
                  <Typography variant={'body2'} className="secondary">
                    Transaction
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>
            {loading && [...Array(10)].map((e, index) => (
              <Skeleton
                key={index}
                height={40}
                sx={{
                  my: '8px',
                  borderRadius: '10px',
                  transform: 'none',
                  overflow: 'hidden',
                  opacity: 0.3
                }}
                animation="wave"
              />
            ))}
            {!loading && data?.map((item: Transaction) => (
              <ListItem key={item.id}>
                <Grid container spacing={1}>
                  <Grid xs={5} item>
                    <Typography variant={'body2'}>
                      {moment.utc(item.create_at).local().format('DD-MM-YY HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid xs={7} item>
                    <StatementIcon
                      kind={item.kind}
                      gems={item.gems}
                      nftId={item.nft?.token_id}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          {pagesCount && pagesCount > 1 && (
            <Box
              component="span"
              sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Pagination
                variant="outlined"
                color="secondary"
                count={pagesCount}
                onChange={handleChange}
                boundaryCount={0}
              />
            </Box>
          )}
        </>
      )}

    </div>
  )
}

export default React.memo(Statement)
