import axios from 'axios';
import {accessToken, refreshToken} from '../store/authSlice';
import {useAppDispatch, useAppSelector} from '../hooks';
import useLocalStorage from './useLocalStorage';
import {lightGreen} from '@mui/material/colors';

export const useRefreshToken = () => {
  const refreshToken = localStorage.getItem('Refresh')

  const refresh = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/metamask/token/refresh/`,
      {
        'refresh': refreshToken,
        // withCredentials: true
      },
    )
    localStorage.setItem('Access', response.data.access)

  }
  return refresh;
}

export default useRefreshToken
