import React from 'react'
import cx from 'classnames'
import './GridItem.scss'

type Props = {
  children: React.ReactNode,
  variant?: 'contained' | 'contained-tiny' | 'gradient' | 'transparent',
  fullHeight?: boolean,
  className?: string,
  marginBottom?: boolean
}

const GridItem: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(
        'GridItem',
        props.variant && `-${props.variant}`,
        props.fullHeight && '-full-height',
        props.marginBottom && '-margin-bottom',
        props.className
        )}
    >
      {props.children}
    </div>
  )
}

export default React.memo(GridItem)
