import React from 'react'
import {ReactComponent as Dashboard} from '../../assets/Dashboard.svg';
import {ReactComponent as Wallet} from '../../assets/Wallet.svg';
import {ReactComponent as Bid} from '../../assets/Bid.svg';
import {ReactComponent as Flask} from '../../assets/Flask.svg';
import {ReactComponent as Save} from '../../assets/Save.svg';
import {ReactComponent as Ingredient} from '../../assets/Ingredient.svg';
import {ReactComponent as Recipe} from '../../assets/Recipe.svg';
import {ReactComponent as Lottery} from '../../assets/Lottery.svg';
import {ReactComponent as Staking} from '../../assets/Staking.svg';
import {ReactComponent as LeaderBoard} from '../../assets/LeaderBoard.svg';
import cx from 'classnames'
import './MenuItem.scss'
import {Tooltip, useMediaQuery, useTheme} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../hooks';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const renderSwitch = (icoName: string) =>{
  switch(icoName) {
    case 'Dashboard':
      return <Dashboard />
    case 'Earn':
      return <Wallet/>
    case 'Staking':
      return <Staking/>
    case 'Lottery':
      return <Lottery/>
    case 'Shop':
      return <Bid/>
    case 'Craft':
      return <Flask/>
    case 'Mint':
      return <Save/>
    case 'Raffles':
      return <Save/>
    case 'Recipes':
      return <Recipe/>
    case 'Ingredients':
      return <Ingredient/>
    case 'LeaderBoard':
      return <LeaderBoard/>
    default:
      return null;
  }
}
type Props = {
  name: string,
  isToggled?: boolean,
  comingSoon?: boolean,
  children?: {name: string, url?: string}[],
  url?: string
}

const Inner = React.forwardRef(function MyComponent(props: Props, ref: React.LegacyRef<HTMLDivElement>) {
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const sideBarMiniVariant = useAppSelector(state => state.global.sideBarMiniVariant)
  const location = useLocation();
  const navigate = useNavigate()
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'))
  useOnClickOutside(buttonRef, () => setOpen(false));

  const handleNavigate = (item: Props) => {
    // just open dropdown if mobile
    if (matchXsWidth && item.children?.length) {
      setOpen(!open);
      return;
    }
    if (item.url) { // otherwise navigate
      if (location.pathname.substring(1) === item.url) return;
      navigate(item.url);
    }
  }

  // close dropdown on route change
  React.useLayoutEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const isActive = (url: string) => {
    if (url === '/') return location.pathname === '/';
    return location.pathname.includes(url);
  }

  const itemClass = cx(
    'button',
    props.url && isActive(props.url) && '-active',
    sideBarMiniVariant && '-toggled',
    props.comingSoon && '-coming-soon',
    matchXsWidth && '-mobile-view',
    matchXsWidth && open && '-opened'
  );

  const { comingSoon, ...otherProps } = props;

  return (
    <div ref={buttonRef}>
      <div onClick={() => handleNavigate(props)} {...otherProps} ref={ref} className={itemClass} >
        <div>
          <div className="icon">{renderSwitch(props.name)}</div>
          <div className="name">{props.name}</div>
        </div>
      </div>
      {props.children && props.children.length && (
        <div className="children">
          {props.children.map((child: Props, index: number) =>
            <div className="child" key={index}>
              <Inner name={child.name} url={child.url} />
            </div>
          )}
        </div>
      )}
    </div>
  )
});

const MenuItem: React.FC<Props> = (props) => {
  return (
    <div className={cx('MenuItem')}>
      {props.comingSoon ? (
          <Tooltip arrow title="Coming Soon" placement="right">
            <Inner name={props.name} comingSoon={true} />
          </Tooltip>
      ) : (
        <Inner url={props.url} children={props.children} name={props.name} />
      )}
    </div>
  )
}

export default React.memo(MenuItem)
