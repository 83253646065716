import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#29EBCC',
    },
    secondary: {
      main: '#2ec5d3',
    },
    error: {
      main: '#FF8E75',
    },
    background: {
      default: '#191C2A',
      paper: '#242A3E',
    },
  },
  typography: {
    fontFamily: 'Nunito',
    fontSize: 16,
    htmlFontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontSize: 40,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h4: {
      color: '#48EBA4',
      fontWeight: 900,
      fontSize: 26,
      lineHeight: 1.2
    },
    body1: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.21,
    },
    button: {
      fontSize: 13,
      textTransform: 'unset',
      borderRadius: 16,
    },
    overline: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.3,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          padding: '11px 22px',
          fontSize: 14,
          minWidth: 122,
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: { background: '#29EBCC' },
        },
        {
          props: { variant: 'outlined' },
          style: { borderRadius: 8, padding: '8px 22px', color: '#29EBCC'},
        },
        {
          props: { color: 'success' },
          style: { color: '#29EBCC', borderColor: '#29EBCC'},
        },
        {
          props: { color: 'error' },
          style: { color: '#FF8E75'},
        },
        {
          props: { color: 'warning' },
          style: {
            color: '#fff',
            background: 'linear-gradient(152.03deg, #81FFD1 -19.27%, #17DA7C 109.91%)',
            boxShadow: '6px 4px 25px rgba(129, 255, 209, 0.34)'
          }
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: 12,
            color: '#fff',
            padding: '7px 22px',
            minWidth: 107,
          }
        },
        {
          props: { size: 'small', variant: 'text' },
          style: {
            color: '#29EBCC',
            fontSize: 14
          }
        },
      ]
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          padding: 36,
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: '-3px',
          right: '-3px'
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          background: 'transparent',
          color: '#ffffff',
          transition: '0.3s ease-in-out',
          border: '1px solid rgba(122,134,161, 0.2)',
          '&:hover': {
            background: 'transparent',
            color: '#29EBCC'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
          minWidth: 122,
          fontWeight: 600,
          'label + &': {
            marginTop: '20px',
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          background: '#1c2033',
          color: '#fff',
          '& input': {
            borderRadius: 10,
            padding: '12px 14px',
          },
          '& legend': {
            display: 'none'
          }
        },
        notchedOutline: {
          borderColor: 'transparent',
          top: 0,
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          overflowX: 'visible',
        },
        paper: {
          border: 'none',
          overflow: 'visible !important',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 0',
          border: 0
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(4px)'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            background: 'transparent',
            backdropFilter: 'unset'
          },
          '& .MuiList-root': {
            margin: 0,
            background: '#242A3E'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: 'calc(100% + 22px)',
          margin: '12px -12px 0 -12px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          margin: '6px 0',
          borderRadius: '10px',
          background: 'rgba(0,0,0,0.2)'
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          background: 'rgba(25,28,42,0.6)',
          backdropFilter: 'blur(4px)'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: '#89C6FF',
          textDecoration: 'none',
          borderBottom: '1px dotted #89C6FF'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#CEEAFF',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
          color: '#000',
          fontSize: '14px',
          padding: '10px 14px',
        },
        arrow: {
          color: '#CEEAFF',
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          opacity: 0.5,
          transform: 'none',
        }
      }
    }
  }
};

export default themeOptions;
