import { configureStore } from '@reduxjs/toolkit'
import userData from './authSlice';
import globalState from './globalSlice';
import cartState from './cartSlice';
import tradeItemsState from './tradeItemsSlice';
import craftState from './craftSlice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {combineReducers} from 'redux';
import thunk from 'redux-thunk';

const reducers = combineReducers({
  cart: cartState,
  user: userData,
  global: globalState,
  tradeItems: tradeItemsState,
  craft: craftState
});
const persistConfig = {key: 'root', storage, whitelist: ['cart']};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
