import React from 'react'
import { useDrag } from 'react-dnd'
import {Ingredient} from '../../types/data-contracts';
import cx from 'classnames';

export interface MovableItemProps {
  name: string
  type: string
  isDropped: boolean
  children?: React.ReactNode
  ingredientData: Ingredient
}

export const MovableItem: React.FC<MovableItemProps> = (
  { name,
    type,
    isDropped,
    children,
    ingredientData
  }) => {

  const [isCaptured, setIsCaptured] = React.useState<boolean>(false);
  const [{ opacity, isDragging }, drag] = useDrag(
    () => ({
      type,
      item: { name, ingredientData },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging()
      }),
    }),
    [name, type],
  );

  React.useEffect(() => {
    if (isCaptured && isDragging) setTimeout(() => {
      setIsCaptured(false);
    }, 100);
  }, [isCaptured, isDragging]);

  return (
    <div
      ref={drag}
      style={{opacity}}
      onMouseDown={() => setIsCaptured(true)}
      onMouseUp={() => setIsCaptured(false)}
      className={cx('_movable-item', isCaptured && '-dragging')}
    >
      {children}
    </div>
  )
}

export default React.memo(MovableItem)