import React from 'react';
import {Alert, Button, IconButton, Snackbar, Tooltip, Typography} from '@mui/material';
import DropFlask from '../DropFlask';
import {Ingredient} from '../../types/data-contracts';
import {ReactComponent as Slot} from '../../assets/mint/slot.svg';
import {ReactComponent as Result} from '../../assets/mint/result.svg';
import {ReactComponent as Question} from '../../assets/mint/question.svg';
import {ReactComponent as HelpBottom} from '../../assets/сraftMashine/help-bottom.svg';
import {ReactComponent as SadFace} from '../../assets/сraftMashine/sad-face.svg';
import cx from 'classnames';
import './MintProcess.scss'


interface Props {
  ingredients?: Ingredient[]
}

const MintProcess: React.FC<Props> = ({ ingredients }) => {

  return (
    <div className={cx('MintProcess')}>
      <div className="description">Drag and drop elements to create a new Monster Ape</div>
      <div className="container">
        <div className="slots">
          <div className="slot slot-1">
            <div className="slot-title">Place an Ape</div>
            <Slot />
          </div>
          <div className="slot slot-2">
            <div className="slot-title">Place Serum</div>
            <Slot />
          </div>
        </div>
        <div className="result">
          <Result />
          <Question className="question" />
        </div>
      </div>
    </div>
  )
}

export default React.memo(MintProcess)
