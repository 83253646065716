import React from 'react';
import { axiosPrivate} from '../api/axios';
import useRefreshToken from './useRefreshToken';
import axios from 'axios';
import {useAppSelector} from '../hooks';
import {useNavigate} from 'react-router-dom';
const useAxiosPrivate = () => {
  const navigate= useNavigate()
  const refresh = useRefreshToken()
  const accessToken = useAppSelector(state => state.user.accessToken)
  const localStorageAccess = localStorage.getItem('Access')

  React.useEffect(() => {
    if (!localStorageAccess) return navigate('/login');
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        // @ts-ignore
        if (!config.headers['Authorization']) {
          // @ts-ignore
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      }, (error) => {
        Promise.reject(error)
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    }
  }, [refresh])
  return axiosPrivate;
}

export default useAxiosPrivate
