import web3Modal from '../utils/initWeb3Modal';
import {ethers} from 'ethers';
import React, {useEffect} from 'react';
import {accessToken, oneTimeNonce, publicAddress, refreshToken} from '../store/authSlice';
import {useAppDispatch, useAppSelector} from '../hooks';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';

const useLogin = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const setAccount = (account: string | undefined) => dispatch(publicAddress(account as string))
  const setNonce = (nonce: string | undefined) => dispatch(oneTimeNonce(nonce as string))
  const setToken = (access: string | undefined) => dispatch(accessToken(access))
  const setRefresh = (refresh: string | undefined) => dispatch(refreshToken(refresh))
  const account = useAppSelector(state => state.user.publicAddress)
  const nonce = useAppSelector(state => state.user.oneTimeNonce)
  const access = useAppSelector(state => state.user.accessToken)
  const setAccess = (access: string | undefined) => dispatch(accessToken(access))
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<{ open: boolean, code?: number, message?: string }>({open: false});
  const [chainId, setChainId] = React.useState<any>();
  const [provider, setProvider] = React.useState<any>();
  const [localSignature, setLocalSignature] = React.useState<string | undefined>(undefined)
  const location = useLocation();
  const [afterConnectLink, setAfterConnectLink] = React.useState('/');

  const refreshState = (skipErrorRefresh = false) => {
    if (!skipErrorRefresh) setAccount(undefined);
    setNonce(undefined);
    setProvider(null);
    if (!skipErrorRefresh) setError({open: false, code: undefined, message: undefined});
    setChainId(null);
  };

  useEffect(() => {
    if (window.ethereum) setProvider(new ethers.providers.Web3Provider(window.ethereum));
  }, []);

  const connectWallet = async (link: string) => {
    setAfterConnectLink(link);
    try {
      await web3Modal.connect()
      const prov = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = async () => await prov.listAccounts();
      setError({open: false, code: undefined, message: undefined});
      accounts()
        .then(response => {
          setAccount(response[0])
          if (!access) getNonce(response[0])
        })
    } catch (error: any) {
      setError({open: true, message: error.message});
    }
  };

  const getNonce = (account: string) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/metamask/login/${account}`)
      .then((response) => {
        setNonce(response.data.nonce)
        setOpen(true)
        return
      }).catch((error: any) => {
      setError({open: true, message: error.response.data.non_field_errors[0]});

      refreshState(true);
      localStorage.removeItem('Access')
      localStorage.removeItem('Refresh')
      // setAccount(undefined)
      setRefresh(undefined)
      disconnect(true).then(() => navigate('/login'))
    })
  }

  const getSignature = async () => {
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum);
      const signer = prov.getSigner();
      const signature = await signer.signMessage(nonce as any);
      setLocalSignature(signature)
    } catch (err: any) {
      setOpen(false)
      setError({
        open: true,
        message: err.message
      })
    }
  }

  const getTokens = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/metamask/login/${account}`, {
      'signature': `${localSignature}`,
      'nonce': nonce,
    })
      .then((response) => {
        if (!response.data.access) return
        setToken(response.data.access)
        setRefresh(response.data.refresh)
        localStorage.setItem('Access', response.data.access)
        localStorage.setItem('Refresh', response.data.refresh)
        setNonce(undefined);
        if (afterConnectLink.includes('steam')) {
          window.location.href = `steam://run/2158200?token=${response.data.access}`;
        } else {
          navigate(afterConnectLink);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }

  React.useEffect(() => {
    if (!nonce) return
    getSignature()
  }, [account, nonce])

  React.useEffect(() => {
    if (!localSignature || !account) return
    getTokens()
  }, [account, localSignature])

  React.useEffect(() => {
    if (access && location.pathname === '/login') {
      navigate('/');
    }

    const handleAccountsChanged = async (accounts: Array<string>) => {
      setAccess(undefined);
      disconnect().then(() => navigate('/login'));
    };

    const handleChainChanged = (hexChainId: string) => {
    };

    const handleDisconnect = () => {
      disconnect().then(() => navigate('/login'));
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('disconnect', handleDisconnect);
    }

    return () => {
      if (window.ethereum?.removeListener) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
        window.ethereum.removeListener('disconnect', handleDisconnect);
      }
    };
  }, []);

  const disconnect = async (skipErrorRefresh = false) => {
    await web3Modal.clearCachedProvider();
    refreshState(skipErrorRefresh);
  };

  return {account, disconnect, connectWallet, chainId, nonce, error, open}
}

export default useLogin;
