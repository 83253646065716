import React from 'react'
import {Box, Button, IconButton, Modal, Typography, Dialog, useTheme} from '@mui/material';
import {ReactComponent as Close} from '../../assets/Close.svg';

type Props = {
  open: boolean
  children: React.ReactNode
  title?: string
  handleClose: () => void
  confirmText?: string
  handleConfirm?: () => void,
  modalStyles?: {}
}

const Modality: React.FC<Props> = (props) => {

  const style = {
    ...{
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      backgroundColor: '#191c2a',
      border: '2px solid #000',
      padding: '24px',
      margin: '10px',
      overflowY: 'visible'
    }, ...(props.modalStyles || {})
  } as any;

  return (
    <Dialog
      className="Modality"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{style}}
    >
      <Box>
        <header>
          <Typography variant="h4" sx={{mb: 2}}>
            {props.title}
          </Typography>
          <IconButton
            onClick={props.handleClose}
            aria-label="Close"
            component="span"
            sx={{position: 'absolute', right: 0, top: 0, color: '#fff'}}
          >
            <Close/>
          </IconButton>
        </header>
        <>{props.children}</>
        {props.confirmText && (
          <Box sx={{justifyContent: 'flex-end', display: 'flex', pt: 2}}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleConfirm}
            >
              {props.confirmText}
            </Button>
            <Button variant="text" color="primary" sx={{ml: 1, pl: 0, pr: 0}} onClick={props.handleClose}>Cancel</Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default React.memo(Modality)
