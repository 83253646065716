import './CraftingStatementItem.scss';
import React from 'react';
import cx from 'classnames';
import {Tooltip} from '@mui/material';

type Props = {
  item: any,
  isResult?: boolean,
  isLast?: boolean,
  borderColor?: string,
  amount?: number,
  tooltip?: {
    ingredient: boolean,
    position: boolean,
    count: -1 | 0 | 1
  }
}

const CraftingStatementItem: React.FC<Props> = (
  {
    item,
    isResult = false,
    isLast = false,
    borderColor = 'yellow',
    amount = undefined,
    tooltip = undefined
  }
) => {
  return (
    <div className={cx(
      'CraftingStatementItem',
      borderColor && `-border-${borderColor}`,
      isResult && '-result',
      isLast && '-last'
    )}>
      <Tooltip arrow title={ !tooltip ? '' :
        <div className="item-tooltip">
          <span>{ tooltip.ingredient ? '✅' : '❌' } ingredient</span>
          <span>{ tooltip.position ? '✅' : '❌' } slot</span>
          {tooltip.count === 1 && <span>⬆️ more items</span>}
          {tooltip.count === -1 && <span>⬇️ less items</span>}
          {tooltip.count === 0 && <span>✅ quantity</span>}
        </div>
      } placement="bottom">
        <div className="image">
          <img src={item.image_thumb} width="40px" height="40px" alt={item.title}/>
          {amount && amount > 1 && <div className="amount">x{amount}</div>}
        </div>
      </Tooltip>
      <div className="content">
        <div className="title" dangerouslySetInnerHTML={
         // @ts-ignore
         {__html: item.title?.replace('level ', 'level&nbsp;')}
       }></div>
      </div>
      {!isResult && !isLast && <div className="plus">+</div>}
    </div>
  )
}

export default React.memo(CraftingStatementItem)
