import React from 'react'
import {Grid, TextField, Typography, Button, Tooltip} from '@mui/material';
import {ReactComponent as ArrowRight} from '../../assets/ArrowRight.svg';
import axios from '../../api/axios';
import {useAppSelector} from '../../hooks';
import './TransferGems.scss'
import Modality from '../Modality/Modality';
import eventBus from '../../EventBus';
import useScrollToLocation from '../../hooks/useScrollToLocation';

type Props = {
  balance?: string
}

const TransferGems: React.FC<Props> = (props) => {
  const account = useAppSelector(state => state.user.publicAddress);
  const balance = useAppSelector(state => state.user.data?.gems_balance);
  const accessToken = useAppSelector(state => state.user.accessToken);
  const [amount, setAmount] = React.useState<string>('');
  const [recipient, setRecipient] = React.useState<string>('');
  const [amountError, setAmountError] = React.useState<{show: boolean, message?: string}>({ show: false });
  const [userError, setUserError] = React.useState<{show: boolean, message?: string}>({ show: false });
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  useScrollToLocation();

  const handleConfirm = () => {
    handleTransfer();
    setOpenModal(false);
  }
  const handleButtonPress = () => {
    if (!isFormValid()) return false;
    setOpenModal(true);
  }
  const [success, setSuccess] = React.useState<boolean>(false);
  const exceptThisSymbols = ['e', 'E', '+', '-'];
  // @ts-ignore
  const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
    first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('');

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess(false);
    setAmount(event.target.value);
    setAmountError({show: false});
  }

  const handleRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess(false);
    setRecipient(event.target.value);
    setUserError({show: false});
  }

  const isFormValid = () => {
    setSuccess(false);
    setAmountError({show: false});
    setUserError({show: false});
    let errors = false;
    if (!amount) {
      setAmountError({show: true, message: 'Amount field is required'});
      errors = true;
    } else {
      if (parseFloat(balance ?? '') < parseFloat(amount)) {
        setAmountError({show: true, message: 'Too much Gems'});
        errors = true;
      }
    }
    if (!recipient) {
      setUserError({show: true, message: 'User field is required'});
      errors = true;
    } else {
      if (recipient.toLowerCase() === (account || '').toLowerCase()) {
        setUserError({show: true, message: 'You can\'t transfer to yourself'});
        errors = true;
      }
    }
    return !errors;
  }

  const handleTransfer = () => {
    if (!isFormValid()) return false;

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/transfer_gems/`,
      {
        address: recipient?.toString(),
        amount: amount?.toString()
      },
      { headers: { 'Authorization': `Bearer ${accessToken}` }}
    ).then((responce) => {
      setSuccess(true);
      setRecipient('');
      setAmount('');
      eventBus.dispatch('TRADE_PURCHASE');
      eventBus.dispatch('UPDATE_STATEMENT');
    }).catch((error) => {
      if (Object.keys(error.response.data).includes('address')) {
        setUserError({show: true, message: 'Address is not correct'});
      }
      if (Object.keys(error.response.data).includes('amount')) {
        setAmountError({show: true, message: 'Value should be greater than 1'});
      }
    });
  }

  return (
    <div className="TransferGems" id="transfer">
      <Grid container sx={{alignItems: 'center'}}>
        <Grid item xs={12} sm={6} sx={{mb: 5}}>
          <Typography component="h2" gutterBottom={false} variant="h4">Transfer Gems</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{mb: 5}}>
          <Typography gutterBottom={false} component="div" variant="body2" sx={{textAlign: {xs: 'left', md: 'right'}}} >
            <span className="primary">Available balance:</span> 💎 {props.balance ? props.balance : 0}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{alignItems: 'flex-start', justifyContent: 'flex-start'}}
        component="form"
        noValidate
        spacing={2}
        autoComplete="off"
      >
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Amount, 💎"
            type="number"
            value={amount}
            placeholder="0.0"
            required={true}
            InputLabelProps={{shrink: true}}
            onChange={handleAmountChange}
            error={amountError.show}
            helperText={amountError.show && amountError.message}
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            error={userError.show}
            helperText={userError.show && userError.message}
            value={recipient}
            label="User"
            placeholder="0xadd…"
            InputLabelProps={{shrink: true}}
            required={true}
            onChange={handleRecipientChange}
          />
        </Grid>
        <Grid item sm={3} xs={12} sx={{mt: {xs: 0, sm: '18px', md: '18px'}}}>
          <Button
            sx={{mt: '-2px'}}
            variant="contained"
            color="primary"
            onClick={handleButtonPress}
            endIcon={<ArrowRight/>}
          >
            Transfer
          </Button>
        </Grid>
        <Grid item xs={12}>
          {success && (
            <Typography variant={'body2'} className="primary">Transfer successful!</Typography>
          )}
        </Grid>
      </Grid>

      <Modality
        open={openModal}
        handleClose={() => setOpenModal(false)}
        confirmText="Transfer"
        handleConfirm={handleConfirm}
      >
        <Typography variant="h6" sx={{ my: 2 }}>
          <b>Are you sure you want to transfer {amount} Gems?</b>
        </Typography>
      </Modality>
    </div>
  )
}

export default React.memo(TransferGems)
