import React from 'react'
import Trade from '../../components/Trade';

const RecipesPage: React.FC = () => (
  <Trade
    title="Recipes"
    description="Buy recipes for crafting"
    text="recipes"
    module="recipes"
  />
)

export default React.memo(RecipesPage)
