import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface globalState {
  loading: boolean,
  sideBarMiniVariant: boolean
}

const globalState = createSlice({
  name: 'globalState',
  initialState: {
    loading: false,
    sideBarMiniVariant: false
  } as globalState,
  reducers: {
    isLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSideBarMiniVariant(state, action: PayloadAction<boolean>) {
      state.sideBarMiniVariant = action.payload
    },
    toggleSideBarMiniVariant(state) {
      state.sideBarMiniVariant = !state.sideBarMiniVariant
    },
  }
})

export const {isLoading, setSideBarMiniVariant, toggleSideBarMiniVariant} = globalState.actions
export default globalState.reducer
