import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Ingredient as IIngredient} from '../types/data-contracts';
import {RootState} from './index';

interface ICartItem {
  item: TradeItem,
  amount: number,
  price: number,
}

interface cartState {
  items: ICartItem[],
}

interface IRecipe extends IIngredient {}
type TradeItem = (IIngredient | IRecipe) & { module: string }

const getItemPrice = (cartItem: Pick<ICartItem, 'item' | 'amount'>) => {
  return parseFloat(cartItem.item.price || '0') * cartItem.amount;
}

const cartState = createSlice({
  name: 'globalState',
  initialState: {
    items: [],
  } as cartState,
  reducers: {
    addEntityToCart(state, {payload}: PayloadAction<TradeItem>) {
      const found = state.items.find(({item}) => item.id === payload.id);
      if (found) {
        found.amount++;
        found.price = getItemPrice(found);
      } else state.items.push({
        item: payload,
        amount: 1,
        price: getItemPrice({item: payload, amount: 1})
      })
    },

    removeEntityFromCart(state, {payload}: PayloadAction<TradeItem>) {
      const found = state.items.find(({item}) => item.id === payload.id);
      if (found) {
        found.amount--;
        found.price = getItemPrice(found);
        if (!found.amount) state.items = state.items.filter(({item}) => item.id !== payload.id);
      }
    },
    clearCart(state, {payload}: PayloadAction<string>) {
      state.items = state.items.filter(({item}) => item.module !== payload);
    }
  }
})

export const getters = {
  amountById: (id: number) => (state: RootState) => state.cart.items.find(({item}) => item.id === id)?.amount || 0,
  itemsByType: (type: string) => (state: RootState) => state.cart.items.filter(({item}) => item.module === type),
  amountDictionary: (state: RootState) => state.cart.items.reduce((acc: any, {item, amount}) => {
    if (item.id) acc[item.id] = amount;
    return acc;
  }, {}),
  totalPriceByType: (type: string) => (state: RootState) => state.cart.items.reduce((acc, {price, item}) => {
    if (item.module === type) acc += price;
    return acc;
  }, 0)
}

export const {addEntityToCart, removeEntityFromCart, clearCart} = cartState.actions
export default cartState.reducer
