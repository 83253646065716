import React from 'react';
import cx from 'classnames';
import './Cart.scss'
import {
  Alert,
  Button, Slide, Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip, Skeleton
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks';
import TradeItemQuantityPicker from '../TradeItem/TradeItemQuantityPicker';
import {useSelector} from 'react-redux';
import {addEntityToCart, clearCart, getters} from '../../store/cartSlice';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import eventBus from '../../EventBus';
import Modality from '../Modality/Modality';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Cart: React.FC<{name: string, module: string}> = ({ name, module }) => {
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const totalPrice = useSelector(getters.totalPriceByType(module));
  const cartItems = useSelector(getters.itemsByType(module));
  const dispatch = useAppDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [snackbar, setSnackbar] = React.useState<{open: boolean, message?: string, type?: 'success' | 'error'}>(
    {open: false, message: undefined, type: 'success'}
  );
  const [buyEnabled, setBuyEnabled] = React.useState<boolean>(false);
  const balance = useAppSelector(state => state.user.data?.gems_balance);

  React.useEffect(() => {
    if (!balance) setBuyEnabled(false);
    else setBuyEnabled(parseFloat(balance) >= totalPrice);
  }, [totalPrice, balance]);

  const buy = async () => {
    try {
      const payload = cartItems.map((i) => ({ingredient_id: i.item.id, amount: i.amount}));
      const response = await axiosPrivate.post(`${apiUrl}/api/v1/lab/${module}/purchase/`, payload);
      setSnackbar({open: true, message: 'Your purchase was successful!', type: 'success'});
      dispatch(clearCart(module));
      eventBus.dispatch('TRADE_PURCHASE');
      localStorage.removeItem('flasks'); // to update crafting
    } catch (e) {
      console.error(e);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    }
  }

  return (
    <div className={cx('Cart', matchXsWidth && '-mobile-view')}>
      {!matchXsWidth && <Typography mb={3} sx={{mr: 2}} variant="h4">Cart</Typography>}
      {cartItems && cartItems.length ? (
        <>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{minWidth: 180}}>
                    <span className="secondary">Title</span>
                  </TableCell>
                  <TableCell sx={{minWidth: 120}}>
                    <div style={{ width: 70, textAlign: 'center' }} className="secondary">Amount</div>
                  </TableCell>
                  <TableCell sx={{minWidth: 72}}>
                    <span className="secondary">Price, 💎</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((cartItem, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {cartItem.item.title}
                    </TableCell>
                    <TableCell>
                      <TradeItemQuantityPicker item={cartItem.item} />
                    </TableCell>
                    <TableCell>
                      {cartItem.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="total">Total {totalPrice} 💎</div>
          <div className="buttons">
            {buyEnabled ?
              <Button size="small" variant="contained" component="div" onClick={buy}>Buy</Button> :
              <Tooltip arrow title="Not enough gems" placement="top">
                <span>
                  <Button size="small" variant="contained" component="div" disabled={true}>Buy</Button>
                </span>
              </Tooltip>
            }
            <Button
              size="small"
              variant="text"
              component="div"
              style={{marginLeft: 6}}
              onClick={() => dispatch(clearCart(module))}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <Typography align="center" variant="h6">
          No {name} in your cart
        </Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default React.memo(Cart)
