import React from 'react';
import {Alert, Button, Snackbar, Tooltip} from '@mui/material';
import './Recipe.scss';
import {ReactComponent as Question} from '../../assets/Question.svg';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import GridItem from '../GridItem/GridItem';
import moment from 'moment';
import Countdown from 'react-countdown';
import {CountdownRendererFn} from 'react-countdown/dist/Countdown';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const countDownRenderer: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    window.location.reload();
  } else {
    return <span>{hours}h {minutes}min {seconds}s</span>
  }
}

const Recipe: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const [recipe, setRecipe] = React.useState<any>({});
  const [myRecipe, setMyRecipe] = React.useState<any>({});
  const [snackbar, setSnackbar] = React.useState<{ open: boolean, message?: string, type?: 'success' | 'error' }>(
    {open: false, message: undefined, type: 'success'}
  );
  const [myRecipeLoaded, setMyRecipeLoaded] = React.useState<boolean>(false);

  const getRecipe = async (my = false) => {
    try {
      const response = await axiosPrivate.get(`${apiUrl}/api/v1/lab/recipes/` + (my ? 'my/' : ''));
      if (my) {
        const recipe = response.data?.results?.[0];
        setMyRecipeLoaded(true);
        if (my && recipe?.recipe?.id) {
          setMyRecipe({...recipe.recipe, slots: recipe?.slots});
        }
      } else {
        const recipe = response.data?.results?.[0];
        setRecipe(recipe);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const buy = async () => {
    try {
      const response = await axiosPrivate.post(`${apiUrl}/api/v1/lab/recipes/purchase/`, [{recipe_id: recipe.id}]);
      setSnackbar({open: true, message: 'Your purchase was successful!', type: 'success'});
      getRecipe(true);
      console.log('buy response', response);
    } catch (e) {
      console.error(e);
      setSnackbar({open: true, message: 'An error occurred', type: 'error'});
    }
  }

  React.useEffect(() => {
    getRecipe(true);
  }, []);

  React.useEffect(() => {
    if (!myRecipe?.id && myRecipeLoaded) getRecipe();
  }, [myRecipe, myRecipeLoaded]);

  return (
    <div className="Recipe">
      {myRecipeLoaded ?
        <>
          {!myRecipe?.id && <div className="item">
            <div className="image">
              <img width="100%" height="auto"
                   src={recipe.image}
                   alt={recipe.title}/>
              <div className="description"><span>x5 Boost</span> with recipe</div>
            </div>
            <div className="content">
              <div className="title">{recipe.title}</div>
              <div className="actions">
                <Tooltip arrow title={
                  <>
                    <span>Expires every 24 hours.</span><br/>
                    <span>You can buy it or find correct formula yourself</span>
                  </>
                } placement="bottom">
                  <Question/>
                </Tooltip>
                <Button
                  size="small"
                  variant="contained"
                  component="div"
                  style={{marginLeft: 'auto'}}
                  onClick={buy}
                >
                  Buy for {recipe.price} Gems
                </Button>
              </div>
            </div>
          </div>}
          {myRecipe?.id && <div className="my-item">
            <GridItem variant="contained">
              <div className="title">{myRecipe.title}</div>
              <div className="list">
                {myRecipe?.slots.map((item: any, index: number) => <div key={index} className="list-item">
                  <div className="list-item-title">Slot #{(index + 1)}</div>
                  <div className="list-item-image">
                    <img width="40px" height="40px" src={item.ingredient.image_thumb} alt={item.ingredient.title}/>
                    <div className="amount">x{ item.amount }</div>
                  </div>
                </div>)}
              </div>
              <div className="bottom">
                <Tooltip arrow title={
                  <>
                    <span>Expires every 24 hours.</span><br/>
                    <span>You can buy it or find correct formula yourself</span>
                  </>
                } placement="bottom">
                  <Question/>
                </Tooltip>
                <div className="timer">
                  <Countdown
                    renderer={countDownRenderer}
                    date={moment.utc(myRecipe?.expire_at).local().valueOf()}
                  />
                </div>
              </div>
            </GridItem>
          </div>}
        </>
        : ''
      }
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        onClose={() => setSnackbar({...snackbar, open: false})}
      >
        <Alert severity={snackbar.type} sx={{width: '100%'}}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default React.memo(Recipe);
