import React from 'react'
import Trade from '../../components/Trade';

const IngredientsPage: React.FC = () => (
  <Trade
    title="Ingredients"
    description="Buy ingredients for crafting"
    text="ingredients"
    module="ingredients"
  />
)

export default React.memo(IngredientsPage)
