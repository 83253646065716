import React, {useEffect} from 'react'
import {Alert, Button, IconButton, Skeleton, Snackbar, Tooltip, Typography} from '@mui/material';
import update from 'immutability-helper'
import DropFlask from '../DropFlask';
import {Ingredient} from '../../types/data-contracts';
import {useAppSelector} from '../../hooks';
import {ReactComponent as Close} from '../../assets/Close.svg';
import {ReactComponent as HelpTop} from '../../assets/сraftMashine/help-top.svg';
import {ReactComponent as Result} from '../../assets/сraftMashine/result-wrapper.svg';
import {ReactComponent as Question} from '../../assets/сraftMashine/question.svg';
import {ReactComponent as HelpBottom} from '../../assets/сraftMashine/help-bottom.svg';
import {ReactComponent as SadFace} from '../../assets/сraftMashine/sad-face.svg';
import axios from 'axios';
import cx from 'classnames';
import './Laboratory.scss'
import eventBus from '../../EventBus';
import useLocalStorage from '../../hooks/useLocalStorage';
import Modality from '../Modality/Modality';
import {usePrompt} from '../../hooks/usePrompt';

interface FlaskState {
  accept: string[]
  lastDroppedItem: any
  counter: any
  num: number,
  data: Ingredient | null,
  active: boolean,
}

interface Props {
  ingredients?: Ingredient[],
  // addItemFunction: (item: any) => void
}

const Laboratory: React.FC<Props> = ({ingredients}) => {
  const accessToken = useAppSelector(state => state.user.accessToken)
  const [enabled, setEnabled] = React.useState<boolean>(true)
  const [error, setError] = React.useState<{ visible: boolean, message: string | undefined }>({
    visible: false,
    message: undefined
  });
  const itemsList = useAppSelector(state => state.tradeItems.items);
  const acceptIngredients = ingredients?.map(function (el) {
    return `${el.id}`;
  }) ?? [];
  const [snackbar, setSnackbar] = React.useState<{ open: boolean, message?: string, type?: 'success' | 'error' }>(
    {open: false, message: undefined, type: 'success'}
  );
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const initialState = [
    {
      num: 1,
      name: 'one',
      accept: acceptIngredients,
      lastDroppedItem: [],
      counter: [],
      data: null,
      active: false
    },
    {
      num: 2,
      name: 'two',
      accept: acceptIngredients,
      lastDroppedItem: [],
      counter: [],
      data: null,
      active: false
    },
    {
      num: 3,
      name: 'three',
      accept: acceptIngredients,
      lastDroppedItem: [],
      counter: [],
      data: null,
      active: false
    }
  ];

  const {storedValue, setStoredValue: setFlasks} = useLocalStorage('flasks');
  const flasks = (storedValue || JSON.parse(JSON.stringify(initialState))) as FlaskState[];
  console.log('flasks', flasks);

  React.useEffect(() => {
    setEnabled(flasks.every((obj) => obj.counter.length));
    setShowClearSlots(flasks.some((obj) => obj.counter.length));
  }, [flasks]);

  const [droppedBoxNames, setDroppedBoxNames] = React.useState<string[]>([])
  const [crafting, setCrafting] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<any>(undefined);
  const [showClearSlots, setShowClearSlots] = React.useState<boolean>(false);

  usePrompt(() => {
    setSnackbar({open: true, message: 'Can\'t leave than crafting is in process!', type: 'error'});
  }, 'Leave screen?', crafting);

  const handleRemoveOne = (index: number) => {
    if (crafting) return undefined;
    const i = --index;
    const flask = Object.values(flasks)[i];
    const items = flask.counter.slice(0, -1);

    setFlasks(
      update(flasks, {
        [i]: {
          lastDroppedItem: !items.length ? {$set: []} : {},
          counter: {$set: items},
          accept: {
            $set: items?.[0]?.ingredientData.id ? [items?.[0]?.ingredientData?.id.toString()] : acceptIngredients
          },
          active: {$set: !!items.length}
        },
      }),
    )
  }

  const handleDoubleClick = (index: number) => {
    const i = --index;
    const flask = Object.values(flasks)[i];
    // if (flask.counter.length) return false;

    setFlasks(
      update(flasks, {
        0: {active: {$set: false}},
        1: {active: {$set: false}},
        2: {active: {$set: false}},
        [i]: {active: {$set: !flask.active}}
      }),
    );
  }

  const handleDrop = React.useCallback(
    (index: number, item: { name: string, ingredientData: Ingredient }) => {
      console.log('crafting', crafting);

      if (crafting) return undefined;
      const {name} = item;

      const myIngredient = itemsList.ingredients.find(({ingredient}) => ingredient?.id === item.ingredientData?.id);
      const itemsInCrafting = flasks.reduce((acc: any, flask: any) => {
        const itemId = flask.counter?.[0]?.ingredientData?.id;
        if (itemId) {
          if (!acc[itemId]) acc[itemId] = 0;
          acc[itemId] += flask.counter.length;
        }
        return acc;
      }, {});

      console.log('q1', flasks[(index - 1)]?.counter?.length >= 5);

      if (flasks[(index - 1)]?.counter?.length >= 5) {
        return undefined;
      }

      console.log('q2', myIngredient.amount <= itemsInCrafting[(item.ingredientData?.id || 0)]);

      if (myIngredient.amount <= itemsInCrafting[(item.ingredientData?.id || 0)]) {
        return undefined;
      }

      setDroppedBoxNames(
        update(droppedBoxNames, name ? {$push: [name]} : {$push: []}),
      );

      setFlasks(
        update(flasks, {
          0: {active: {$set: false}},
          1: {active: {$set: false}},
          2: {active: {$set: false}},
          [--index]: {
            lastDroppedItem: {
              $set: item,
            },
            counter: item ? {$push: [item]} : {$push: []},
            accept: {
              $set: item.ingredientData.id ? [item.ingredientData.id.toString()] : acceptIngredients
            },
            active: {$set: true}
          },
        }),
      )
    },
    [droppedBoxNames, flasks, itemsList],
  )

  const handleCraft = async () => {
    if (!enabled) return;

    setResult(undefined);
    setFlasks(
      update(flasks, {
        0: {active: {$set: false}},
        1: {active: {$set: false}},
        2: {active: {$set: false}},
      }),
    );
    setCrafting(true);
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/lab/craft`,
      {
        'one': {
          'ingredient': flasks[0].lastDroppedItem.ingredientData.id,
          'amount': flasks[0].counter.length
        },
        'two': {
          'ingredient': flasks[1].lastDroppedItem.ingredientData.id,
          'amount': flasks[1].counter.length
        },
        'three': {
          'ingredient': flasks[2].lastDroppedItem.ingredientData.id,
          'amount': flasks[2].counter.length
        }
      },
      {headers: {'Authorization': `Bearer ${accessToken}`}}
    ).then((response) => {
      setTimeout(() => {
        console.log('response', response.data);
        setCrafting(false);
        setResult(response.data);
        setOpenModal(true);
      }, 2000)
    }).catch((error) => {
      console.log('error', error);
      setCrafting(false);
      setFlasks(initialState);
      setError({visible: true, message: error?.response?.data?.message || error?.response?.data?.non_field_errors});
      setSnackbar({open: true, message: 'Your craft failed', type: 'error'});
      eventBus.dispatch('CRAFT_SUCCESS');
    })
  }

  React.useEffect(() => {
    const onLaboratoryItemSelected = (event: any) => {
      const item = event.detail;
      const activeFlaskIndex = flasks.findIndex(({active}) => active);
      if (activeFlaskIndex > -1) {
        const index = activeFlaskIndex + 1;
        handleDrop(index, {
          name: item.ingredient.title,
          ingredientData: item.ingredient,
        });
      }
    }
    eventBus.on('LABORATORY_ITEM_SELECTED', onLaboratoryItemSelected);

    return () => {
      eventBus.remove('LABORATORY_ITEM_SELECTED', onLaboratoryItemSelected);
    }
  }, [flasks]);

  const checkEventTargets = (event: any, classNames: string[]) => {
    return classNames.some((target: string) => {
      return event.target.classList.contains(target) || event.target.closest(`.${target}`);
    });
  }

  const handleModalClose = () => {
    handleClear();
    setResult(undefined);
    setOpenModal(false);
    eventBus.dispatch('CRAFT_SUCCESS');
    eventBus.dispatch('TRADE_PURCHASE');
  }

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (!checkEventTargets(event, [
  //       'DropFlask',
  //       '_laboratory-item',
  //       '_laboratory-selected-item-image'
  //     ])) {
  //       const activeFlaskIndex = flasks.findIndex(({active}) => active);
  //       if (activeFlaskIndex > -1) {
  //         setFlasks(
  //           // @ts-ignore
  //           update(flasks, {
  //             [activeFlaskIndex]: {active: {$set: false}},
  //           }),
  //         )
  //       }
  //     }
  //   }
  //
  //   document.addEventListener('dblclick', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('dblclick', handleClickOutside);
  //   };
  // }, [flasks]);

  const handleClear = () => {
    console.log('handleClear', initialState);
    setFlasks(initialState);
  }

  // React.useEffect(() => {
  //   eventBus.on('TRADE_PURCHASE', handleClear);
  //
  //   return () => {
  //     eventBus.remove('TRADE_PURCHASE', handleClear);
  //   }
  // }, []);

  const handleLaboratoryClick = () => {
    if (error.visible) setError({visible: false, message: undefined});
  }

  return (
    <div className="lab-container">
      <div className="header">
        <Typography component="h2" variant="h4">Laboratory</Typography>
        {error.visible && (
          <div>
            <Typography variant="body2" sx={{color: '#eb6329', mt: 1}} component="div">
              {error.message}
              <Tooltip arrow title="OK, I got it" placement="bottom">
                <IconButton
                  size="small"
                  sx={{color: '#eb6329', width: '26px', height: '26px', ml: '6px'}}
                  onClick={() => setError({visible: false, message: undefined})}
                >
                  <Close/>
                </IconButton>
              </Tooltip>
            </Typography>
          </div>
        )}
      </div>

      <div className={cx('Laboratory', error.visible && '-failed-craft')} onClick={handleLaboratoryClick}>

        <div className="dnd-container">
          {flasks.map(({accept, lastDroppedItem, counter, active, data}, index) => (
            <DropFlask
              crafting={crafting}
              accept={error.visible ? [] : accept}
              data={data}
              lastDroppedItem={lastDroppedItem}
              onDrop={(item) => handleDrop(index, item)}
              onRemove={() => handleRemoveOne(index)}
              onAdd={(item) => handleDrop(index, item)}
              key={index}
              counter={counter}
              num={++index}
              active={active}
            />
            // onDoubleClick={() => handleDoubleClick(index)}
            // onImageDoubleClick={() => handleRemoveOne(index)}
          ))}
          <div className="result">
            <Result className={cx('bg color-listener', crafting && '-crafting')}/>
            {result?.id
              ? <img className="crafted" src={result?.image_thumb} alt="crafted"/>
              : error.visible
                ? <SadFace/>
                : <Question className={cx('question', crafting && '-crafting')}/>
            }
          </div>
          <Button
            variant="contained"
            color="primary"
            className="craft-btn"
            disabled={!enabled || error.visible}
            onClick={handleCraft}
          >
            Craft
          </Button>
          {/*enabled || !error.visible*/}
          {showClearSlots && (
            <div className="clear">
              <Tooltip arrow title="Clear slots" placement="bottom">
                <IconButton onClick={handleClear}>
                  <Close/>
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div className="helper-text">
            <HelpTop className="decoration color-listener"/>
            <Typography className="color-listener" variant="body2" sx={{my: 1}}>
              Drag and drop elements<br/>
              in small circles to create<br/>
              the next level ingredient<br/>
              or serum
            </Typography>
            <HelpBottom className="decoration color-listener"/>
          </div>
        </div>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          onClose={() => setSnackbar({...snackbar, open: false})}
        >
          <Alert severity={snackbar.type} sx={{width: '100%'}}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>

      <Modality
        open={openModal}
        modalStyles={{
          width: '480px',
          overflowY: 'visible'
        }}
        handleClose={handleModalClose}
      >
        <div className="craft-modal">
          <div className="craft-modal-image">
            <img width="105px" height="105px" src={result?.image_thumb} alt={result?.title}/>
            {result?.amount > 1 && <div className="amount">x{result?.amount}</div>}
          </div>
          <div className="craft-modal-title">Congratulations!</div>
          <div className="craft-modal-text">
            You have crafted {result?.title}!
          </div>
        </div>
      </Modality>
    </div>
  )
}

export default React.memo(Laboratory);
