import React from 'react';
import cx from 'classnames';
import './QuantityPicker.scss'

type Props = {
  onAdd?: () => void,
  onSub?: () => void,
  value: number,
  styleType: 'bordered' | 'default'
}

const QuantityPicker: React.FC<Props> = (props) => {
  return (
    <div className={cx('QuantityPicker', `QuantityPicker-${props.styleType}`)}>
      <button className="controller controller-minus" onClick={props.onSub}></button>
      <input type="text" className="screen" value={props.value} readOnly />
      <button className="controller controller-plus" onClick={props.onAdd}></button>
    </div>
  )
}

export default React.memo(QuantityPicker)