import './CraftingStatement.scss'
import {Box, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import React from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CraftingStatementItem from '../CraftingStatementItem';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {setLoading, setStatement, setPagesCount} from '../../store/craftSlice';
import eventBus from '../../EventBus';

type Props = {}

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const PER_PAGE = 3;

const CraftingStatement: React.FC<Props> = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const items = useAppSelector(state => state.craft.statement);
  const isLoading = useAppSelector(state => state.craft.isLoading);
  const pagesCount = useAppSelector(state => state.craft.pagesCount);
  const dispatch = useAppDispatch();

  const getData = async (page: number) => {
    dispatch(setLoading(true));
    try {
      const {data} = await axiosPrivate.get(
        `${apiUrl}/api/v1/lab/craft/statements/`,
        {params: {page: page, page_size: PER_PAGE}}
      );
      dispatch(setStatement(data.results));
      dispatch(setPagesCount(Math.ceil(data.count / PER_PAGE)));
      dispatch(setLoading(false));
    } catch (error) {
      console.error(error)
    }
  }

  const paginationNavigate = (page: number) => {
    getData(page);
  }

  React.useEffect(() => {
    const init = () => {
      paginationNavigate(1);
    }
    init();

    eventBus.on('CRAFT_SUCCESS', init);
    return () => { eventBus.remove('CRAFT_SUCCESS', init); }
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    paginationNavigate(value);
  };

  return (
    <div className="CraftingStatement">
      <Typography style={{marginBottom: 14}} component="h3" variant="h4">Statement</Typography>
      <div className="history-items">
        {isLoading || (!isLoading && items.length) ? (
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className="secondary table-header-cell">#</span>
                </TableCell>
                <TableCell>
                  <div className="secondary table-header-cell">Slot #1</div>
                </TableCell>
                <TableCell>
                  <div className="secondary table-header-cell">Slot #2</div>
                </TableCell>
                <TableCell>
                  <div className="secondary table-header-cell">Slot #3</div>
                </TableCell>
                <TableCell>
                  <div className="secondary table-header-cell">Result</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && [...Array(PER_PAGE)].map((e, index) => (
                <TableRow key={index}>
                  {[...Array(5)].map((e, index) => (
                    <TableCell key={`t_${index}`}>
                      <div className="skeleton-container">
                        <Skeleton sx={{borderRadius: '10px', width: '100%'}} key={index} animation="wave" height="24px"/>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {!isLoading && items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div style={{marginRight: '30px'}}>{item.id}</div>
                  </TableCell>
                  <TableCell>
                    {item.left_slot && <CraftingStatementItem
                      borderColor={item.recipe_matching.one}
                      item={item.left_slot}
                      amount={item.left_slot_amount}
                      tooltip={item.recipe_matching?.match_params?.one}
                    />}
                  </TableCell>
                  <TableCell>
                    {item.bottom_slot && <CraftingStatementItem
                      borderColor={item.recipe_matching.two}
                      item={item.bottom_slot}
                      amount={item.bottom_slot_amount}
                      tooltip={item.recipe_matching?.match_params?.two}
                    />}
                  </TableCell>
                  <TableCell>
                    {item.right_slot && <CraftingStatementItem
                      borderColor={item.recipe_matching.three}
                      item={item.right_slot}
                      isLast={true}
                      amount={item.right_slot_amount}
                      tooltip={item.recipe_matching?.match_params?.three}
                    />}
                  </TableCell>
                  <TableCell>
                    {item.result && <CraftingStatementItem item={item.result} isResult={true}/>}
                    {!item.result && item.status === 'fail' && <div className="failed">Failed</div>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : 'No items yet'}
        {pagesCount && pagesCount > 1 ?
          <Box
            component="span"
            sx={{mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          >
            <Pagination
              variant="outlined"
              color="secondary"
              count={pagesCount}
              onChange={handleChange}
              boundaryCount={0}
            />
          </Box>
          : ''}
      </div>
    </div>
  )
}

export default React.memo(CraftingStatement)
