import React from 'react'
import {
  Box, CssBaseline,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import cx from 'classnames';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {ReactComponent as Logo} from '../../assets/Logo.svg';
import {ReactComponent as LogoText} from '../../assets/LogoText.svg';
import {ReactComponent as Hamburger} from '../../assets/Hamburgermenu.svg';
import MenuItem from '../MenuItem';
import './Sidebar.scss'
import {useAppDispatch, useAppSelector} from '../../hooks';
import {setSideBarMiniVariant, toggleSideBarMiniVariant} from '../../store/globalSlice';

const drawerWidth = 240;

const tabs = [
  {name: 'Dashboard', url: '/'},
  {
    name: 'Earn',
    url: 'earn',
    children: [
      {name: 'Staking', url: 'earn/staking'},
      {name: 'Lottery', url: 'earn/lottery'}
    ]
  },
  {
    name: 'Shop',
    // comingSoon: true,
    url: 'shop'
  },
  // {
  //   name: 'Shop',
  //   // comingSoon: true,
  //   url: 'shop',
  //   children: [
  //     {name: 'Ingredients', url: 'shop/ingredients'},
  //     {name: 'Recipes', url: 'shop/recipes'}
  //   ]
  // },
  {name: 'Craft', url: 'craft'},
  {name: 'Raffles', url: 'raffles'},
  {name: 'LeaderBoard', url: 'leader-board'},
  {name: 'Mint', comingSoon: true},
  // {name: 'Mint', url: 'mint'}
]

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 30px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 2,
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      background: 'red',
      overflow: 'visible',
      '& .MuiDrawer-paper': {
        background: theme.palette.background.paper,
        flexDirection: 'row',
        width: '100%',
        height: '60px',
        bottom: 0,
        top: 'unset',
        alignItems: 'flex-start',
        overflow: 'visible'
      },
      '& .MuiBox-root' : {
        width: '100%',
        overflow: 'visible',
      },
    },
  }),
);

const SideBar: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const sideBarMiniVariant = useAppSelector(state => state.global.sideBarMiniVariant)
  const matchMdWidth = useMediaQuery(theme.breakpoints.up('md'))
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const handleToggle = () => { dispatch(toggleSideBarMiniVariant()) }
  React.useEffect(() => { dispatch(setSideBarMiniVariant(!matchMdWidth)) }, [matchMdWidth])

  return (
    <div className={cx('Sidebar', !sideBarMiniVariant && '-opened', matchXsWidth && '-mobile-view')}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className="Drawer"
        open={!sideBarMiniVariant}>
        <DrawerHeader>
          <div className="logo">
            <Logo/>
            {!sideBarMiniVariant && <LogoText/>}
          </div>
          <IconButton className="hamburger" onClick={handleToggle}>
            <Hamburger />
          </IconButton>
        </DrawerHeader>
        <Box sx={{ overflow: 'auto' }} className="menu">
          {tabs.map((tab, index) => (
            <MenuItem key={index} {...tab} />
          ))}
        </Box>
        <a className="terms" href="/terms">Terms of Service</a>
      </Drawer>
    </div>
  )
}

export default React.memo(SideBar)
