import React from 'react'
import {useDrop} from 'react-dnd'
import cx from 'classnames';
import {ReactComponent as IngredientBg} from '../../assets/сraftMashine/ingredient.svg';
import './DropFlask.scss'
import {Ingredient} from '../../types/data-contracts';

interface DustbinProps {
  accept: string[]
  lastDroppedItem?: any
  onDrop: (item: any) => void
  onRemove: (item: any) => void
  onAdd: (item: any) => void
  onDoubleClick?: (item: any) => void
  onImageDoubleClick?: (item: any) => void
  num: number
  counter: []
  thumb?: string
  data: Ingredient | null
  crafting: boolean,
  active: boolean
}

const DropFlask: React.FC<DustbinProps> = (
  {
    accept,
    lastDroppedItem,
    onDrop,
    onAdd,
    onRemove,
    num,
    counter,
    crafting,
    active,
    onDoubleClick,
    onImageDoubleClick
  }) => {

  const [{isOver, canDrop}, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: counter.length <= 4 && monitor.canDrop(),
    }),
  });

  const isActive = (isOver && canDrop) || active;

  return (
    <div
      ref={drop}
      className={cx(
        'DropFlask',
        `num-${num}`,
        isActive && '-hovered',
        crafting && '-crafting'
      )}
      onDoubleClick={onDoubleClick}
    >
      {lastDroppedItem.ingredientData ?
        (
          <div className="image">
            <img
              width="62px"
              height="62px"
              src={lastDroppedItem.ingredientData?.image_thumb}
              alt={lastDroppedItem.ingredientData?.title}
              className="_laboratory-selected-item-image"
              onDoubleClick={onImageDoubleClick}
            />
            {counter && counter.length > 1 && (
              <div className="amount">
                {(counter.length >= 5 && false) ? 'filled' : `x${counter.length}`}
              </div>
            )}
            {counter && counter.length && <div className="minus" onClick={onRemove}>-</div>}
            {counter && counter.length && counter.length < 5 && <div className="plus" onClick={() => onAdd(lastDroppedItem)}>+</div>}
          </div>
        ) : <span className="color-listener">{num}</span>}
      <IngredientBg className="ingredient color-listener"/>
    </div>
  )
}

export default React.memo(DropFlask)
