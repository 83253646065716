import React from 'react'
import {Button, Grid, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import cx from 'classnames'
import mining from '../../assets/Mining.png'
import mining2x from '../../assets/Mining@2x.png'
import './Lottery.scss'
import {useNavigate} from 'react-router-dom';

const Lottery: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Grid
      container
      direction={matchXsWidth ? 'column' : 'row'}
      justifyContent="start"
      spacing={0}
      className="Lottery"
    >
      <Grid item sm={6} xs={12} sx={{flex: 1}}>
        <Typography component="h2" variant="h4">Lottery</Typography>
        <Typography variant="body2" sx={{my: 2}} className="secondary" component="p">
          Try your luck and increase your gems balance!<br/>
          Spin that one-armed bandit!
        </Typography>
        <Button
          variant="contained"
          color="warning"
          onClick={() => navigate('/earn/lottery')}
        >
          Try Your Luck
        </Button>
      </Grid>
      <Grid item sm={6} xs={12} sx={{flex: 1, position: 'relative'}}>
        <div className={cx('image-wrapper', matchXsWidth && '-mobile-view')}>
          <img src={mining} srcSet={`${mining} 1x, ${mining2x} 2x`} alt="Lottery"/>
        </div>
      </Grid>
    </Grid>
  )
}

export default React.memo(Lottery)
