import React from 'react';
import cx from 'classnames';
import './MyTradeItems.scss'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, useMediaQuery, useTheme
} from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useIntersectionObserver} from 'react-intersection-observer-hook';
import eventBus from '../../EventBus';
import {MovableItem} from '../Laboratory/MovableItem';
import useLocalStorage from '../../hooks/useLocalStorage';
import {useAppDispatch} from '../../hooks';
import {setItems, setLoading} from '../../store/tradeItemsSlice';
import {useSelector} from 'react-redux';
import {getters} from '../../store/tradeItemsSlice';
import {getSkeletons} from '../../utils/getSkeletons';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

type Props = {
  name: string,
  module: 'ingredients' | 'recipes' | 'serums',
  amountVisible: boolean,
  hasHeader: boolean,
  useMobileView?: boolean
  useDraggable?: boolean,
  onDoubleClick?: (item: any) => void,
  checkCraftingAmount?: boolean,
  itemKey?: string
}

const MyTradeItems: React.FC<Props> = ({itemKey = 'ingredient', ...props}) => {
  const {storedValue: flasks} = useLocalStorage('flasks');
  const dispatch = useAppDispatch();
  const axiosPrivate = useAxiosPrivate();
  const theme = useTheme();
  const matchXsWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsList = useSelector(getters.itemsByType(props.module));
  const isItemsLoading = useSelector(getters.loadingByType(props.module));
  const [next, setNext] = React.useState<string | null>(null);
  const [ref, {entry}] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const [craftingAmount, setCraftingAmount] = React.useState<any>({});

  const getData = async (url: string, add: boolean) => {
    try {
      if (add) {
        dispatch(setItems({key: props.module, value: [...itemsList, ...getSkeletons(20, Date.now())]}));
      }
      const response = await axiosPrivate.get(url);
      let items = response.data.results;
      if (props.module !== 'serums') {
        items = items?.sort(
          (a: any, b: any) => a.ingredient.rarity.localeCompare(b.ingredient.rarity) || a.ingredient.level - b.ingredient.level
        );
      }
      if (props.module === 'serums') {
        console.log('items', items);
      }
      if (add) {
        dispatch(setItems({
          key: props.module,
          value: [...itemsList?.filter(({skeleton}) => !skeleton), ...items]
        }));
      } else dispatch(setItems({key: props.module, value: items}));
      setTimeout(() => {
        dispatch(setLoading({key: props.module, value: false}));
      }, 100);
      setNext(response.data.next);
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    isVisible && next && getData(next, true);
  }, [isVisible]);

  React.useEffect(() => {
    const updateData = () => {
      getData(`${apiUrl}/api/v1/lab/${props.module}/my?page_size=999`, false)
    };

    updateData();
    eventBus.on('TRADE_PURCHASE', updateData);

    return () => {
      eventBus.remove('TRADE_PURCHASE', updateData);
    }
  }, []);

  React.useEffect(() => {
    if (props.checkCraftingAmount) {
      const itemsInCrafting = flasks?.reduce((acc: any, flask: any) => {
        const itemId = flask.counter?.[0]?.ingredientData?.id;
        if (itemId) {
          if (!acc[itemId]) acc[itemId] = 0;
          acc[itemId] += flask.counter.length;
        }
        return acc;
      }, {}) || {};

      const craftingAmount = itemsList?.reduce((acc: any, item: any) => {
        const id = item.ingredient?.id;
        acc[id] = item.amount - (itemsInCrafting[id] || 0);
        return acc;
      }, {}) || {};

      setCraftingAmount(craftingAmount);
    }
  }, [flasks, itemsList, props.checkCraftingAmount]);

  return (
    <div className={cx('MyIngredients', matchXsWidth && '-mobile-view')}>
      {!matchXsWidth && <Typography mb={3} sx={{mr: 2}} variant="h4">My {props.name}</Typography>}
      {isItemsLoading ?
        [...Array(5)].map((e, index) => (
          <Skeleton sx={{mb: 2, borderRadius: '10px'}} key={index} animation="wave" height="38px"/>
        ))
        :
        <>
          {itemsList && itemsList.length ? (
            <>
              {matchXsWidth || props.useMobileView ? (
                <div className="ingredients-list">
                  {itemsList.map((item, index) => (
                    <div key={index} className="ingredient">
                      {item.skeleton
                        ? <>
                          <Skeleton sx={{borderRadius: '10px', mb: '16px'}} animation="wave" height="194px"/>
                          <Skeleton sx={{borderRadius: '10px', mb: '6px'}} animation="wave" height="16px"/>
                          <Skeleton sx={{borderRadius: '10px'}} animation="wave" height="24px"/>
                        </>
                        : <>
                          <div className="image" style={{backgroundImage: `url(${item?.[itemKey]?.image_thumb})`}}>
                            <div className="amount">x{item?.amount}</div>
                          </div>
                          <div className="content">
                            <div className="title">{item?.[itemKey]?.title}</div>
                            <div className="description secondary">{item?.[itemKey]?.description}</div>
                          </div>
                        </>
                      }
                    </div>
                  ))}
                  <div ref={ref} style={{height: '1px', width: '100%'}}/>
                </div>
              ) : (
                <TableContainer sx={{maxHeight: 440}}>
                  <Table aria-label="table">
                    {props.hasHeader && (
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{width: '100%'}}>
                            <span className="secondary">Title</span>
                          </TableCell>
                          {props.amountVisible && (
                            <TableCell sx={{width: 70}}>
                              <div className="secondary">Amount</div>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {itemsList.map((item, index) => (
                        <TableRow key={index}>
                          {item.skeleton ? <TableCell colSpan={999}>
                              <Skeleton sx={{borderRadius: '10px'}} key={index} animation="wave" height="38px"/>
                            </TableCell> :
                            <>
                              <TableCell>
                                <div className="ingredient">
                                  {props.useDraggable && craftingAmount[item?.[itemKey]?.id] ? (
                                    <MovableItem
                                      key={index}
                                      name={item?.[itemKey]?.title}
                                      type={`${item?.[itemKey]?.id}`}
                                      isDropped={false}
                                      ingredientData={item?.[itemKey]}
                                    >
                                      <div
                                        className="image _laboratory-item"
                                      >
                                        {/*onDoubleClick={() => props.onDoubleClick && props.onDoubleClick(item)}*/}
                                        <img width="62px" height="62px" src={item?.[itemKey]?.image_thumb}
                                             alt={item?.[itemKey]?.title}/>
                                        {!props.amountVisible && (
                                          <div className="amount">x{craftingAmount[item?.[itemKey]?.id]}</div>
                                        )}
                                      </div>
                                    </MovableItem>
                                  ) : (
                                    <div className={cx(
                                      'image',
                                      !props.amountVisible && !craftingAmount[item?.[itemKey]?.id] && '-semitransparent'
                                    )} draggable="false">
                                      <img
                                        draggable="false"
                                        width="62px"
                                        height="62px"
                                        src={item?.[itemKey]?.image_thumb}
                                        alt={item?.[itemKey]?.title}
                                      />
                                      {!props.amountVisible && (
                                        <div className="amount">x{craftingAmount[item?.[itemKey]?.id]}</div>
                                      )}
                                    </div>
                                  )}
                                  <div className="content">
                                    <div className="title">{item?.[itemKey]?.title}</div>
                                    <div className="description secondary">
                                      <div>{item?.[itemKey]?.description}</div>
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                              {props.amountVisible && (
                                <TableCell>
                                  {item.amount}
                                </TableCell>
                              )}
                            </>
                          }
                        </TableRow>
                      ))}
                      <TableRow ref={ref} style={{height: '1px', width: '100%'}}/>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          ) : (
            <Typography align="center" variant="h6">
              No {props.name} yet
            </Typography>
          )}
        </>
      }
    </div>
  )
}

export default React.memo(MyTradeItems)
